<div class="container">
  <div class="content">
    <div class="heading-text title">Our Community</div>
    <div class="image-container">
      <img
        src="https://res.cloudinary.com/alovesupreme/image/upload/f_auto,t_community/v1617389999/assets/hopesoap.jpg"
        alt=""
      />
    </div>
    <div class="small-heading">
      A beautiful collaboration of hearts by aLoveSupreme and O’live.
    </div>
    <p class="info-text">
      By buying this soap you are supporting the work of an incredible women,
      Rosie Mashale, known affectionately as Mama Rosie. 100% proceeds of this
      heartfelt initiative will go to support Mama Rosie’s Children’s Home,
      Baphumelele.
    </p>

    <div class="small-heading">
      The vision of Baphumelele is to provide shelter and early childhood care.
    </div>
    <p class="info-text">
      Baphumelele provides for vulnerable/orphaned children with chronic
      diseases and HIV/Aids in the Khayelithsa and surrounding areas.
      Baphumelele aims to impact these children’s lives in such a way that they
      will go on to make a difference in their society and beyond.
    </p>
    <div class="ctas">
      <a
        [routerLink]="['/', 'shop', 'products']"
        [queryParams]="{ category: 'SOAP' }"
        ><button appButton [hoverEffect]="true" label="Buy Hope Soap"></button>
      </a>
      <button
        appButton
        [outline]="true"
        [hoverEffect]="true"
        label="More Info"
      ></button>
    </div>
  </div>
</div>
