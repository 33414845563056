import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '../models/user.model';
import { UserService } from '../services/user.service';

@Pipe({
	name: "myCurrency",
})
export class MyCurrencyPipe implements PipeTransform {
	public user$: BehaviorSubject<User>;
	constructor(private us: UserService) {
		this.user$ = this.us.user$;
	}

	transform(value: number | undefined | null, digits?: string): string | null {
		let amt = value || 0;
		let currencyCode = "ZAR";
		let di = digits || (amt % 1 == 0 ? "1.0-0" : "1.2-2");
		const userRegion = this.user$.value?.region;

		switch (userRegion) {
			case "US":
				currencyCode = "USD";
				break;
			case "EU":
				currencyCode = "EUR";
				break;
			case "UK":
				currencyCode = "GBP";
				break;
			case "EU2":
				currencyCode = "EUR";
				break;
			default:
				currencyCode = "ZAR";
				break;
		}

		const cp = new CurrencyPipe("en-ZA");
		return cp.transform(value, currencyCode, "symbol-narrow", di);
	}
}
