<p-toast position="top-right" key="tr"></p-toast
><p-confirmDialog
	[style]="{ width: '40vw' }"
	[baseZIndex]="1000000"
	rejectButtonStyleClass="p-button-text"
></p-confirmDialog>

<!-- <div *ngIf="placingOrder" class="overlay">
  <div class="text">Placing Order</div>
  <p-progressSpinner
    [style]="{ width: '50px', height: '50px' }"
    styleClass="custom-spinner"
    strokeWidth="8"
    fill="#EEEEEE"
    animationDuration=".5s"
  ></p-progressSpinner>
</div> -->

<!-- SA Version -->
<div *ngIf="currency == 'ZAR'" class="container">
	<div class="cart-summary-title">Summary</div>
	<!-- <hr /> -->
	<p-accordion [multiple]="true">
		<p-accordionTab [(selected)]="panelStatus[0]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Order Total (incl. VAT)</p>
					<p class="amount">
						{{
							(itemsAmount$ | async)?.inclVAT
								| currency: "ZAR":"symbol-narrow":"1.2-2"
						}}
					</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="cart-items-total">
					<p class="label subtotal">Subtotal (excl. V.A.T.)</p>
					<p class="amt subtotal">
						{{
							itemsSubtotal | async | currency: "ZAR":"symbol-narrow":"1.2-2"
						}}
					</p>
					<p class="label vat">V.A.T. (15%)</p>
					<p class="amt vat">
						{{ itemsVAT | async | currency: "ZAR":"symbol-narrow":"1.2-2" }}
					</p>
					<p class="label subtotal">Total (incl. V.A.T.)</p>
					<p class="amt subtotal">
						{{
							(itemsAmount$ | async)?.inclVAT
								| currency: "ZAR":"symbol-narrow":"1.2-2"
						}}
					</p>
				</div>
			</ng-template>
		</p-accordionTab>
		<p-accordionTab [(selected)]="panelStatus[1]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Shipping</p>
					<p class="amount">
						{{
							selectedShippingMethod == shippingMethods[0]
								? "TBC"
								: selectedShippingMethod == shippingMethods[1]
								? "Free"
								: "Select Method"
						}}
					</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="shipping-stuff">
					<div class="shipping-method">
						<div class="shipping-method-label">
							Please Select A Shipping Method
						</div>
						<div class="shipping-method-select">
							<div class="method" *ngFor="let option of shippingMethods">
								<p-radioButton
									(onClick)="selectShippingMethod(option)"
									name="groupname"
									[value]="option"
									[label]="option.label"
									[(ngModel)]="selectedShippingMethod"
								></p-radioButton>
							</div>
						</div>
					</div>

					<!-- <div class="shipping-costs">
            <p class="label shipping">Shipping Total (incl. VAT)</p>
            <p class="amt shipping">
              {{
                shippingAmount == null
                  ? "Select Method"
                  : (shippingAmount | currency: "ZAR":"symbol-narrow":"1.2-2")
              }}
            </p>
          </div> -->
				</div>
			</ng-template>
		</p-accordionTab>
		<p-accordionTab [(selected)]="panelStatus[2]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Order Notes</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="order-notes-container">
					<p>
						Please enter any pertinent information you would like us to know
						about your order.
					</p>
					<textarea
						[(ngModel)]="orderNotes"
						name="notes"
						id="order-notes"
					></textarea>
					<!-- <div class="shipping-costs">
            <p class="label shipping">Shipping Total (incl. VAT)</p>
            <p class="amt shipping">
              {{
                shippingAmount == null
                  ? "Select Method"
                  : (shippingAmount | currency: "ZAR":"symbol-narrow":"1.2-2")
              }}
            </p>
          </div> -->
				</div>
			</ng-template>
		</p-accordionTab>
	</p-accordion>
	<!-- <hr /> -->
	<div *ngIf="!hideCTA" class="cta">
		<button
			(click)="confirmOrder()"
			appButton
			[cta]="true"
			label="Place Order"
		></button>
	</div>
	<!-- <hr *ngIf="!hideCTA" /> -->
</div>

<!-- US VERSION -->
<div *ngIf="currency == 'USD'" class="container">
	<div class="cart-summary-title">Summary</div>
	<!-- <hr /> -->
	<p-accordion [multiple]="true">
		<p-accordionTab [(selected)]="panelStatus[0]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Order Total</p>
					<p class="amount">
						{{ (itemsAmount$ | async)?.inclVAT | myCurrency: "1.2-2" }}
					</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="cart-items-total">
					<p class="label subtotal">Items Cost</p>
					<p class="amt subtotal">
						{{ itemsSubtotal | async | myCurrency: "1.2-2" }}
					</p>
					<p class="label vat">Shipping Cost</p>
					<p class="amt vat">
						{{ 30 | myCurrency: "1.2-2" }}
					</p>
					<p class="label subtotal">Total</p>
					<p class="amt subtotal">
						{{ (itemsAmount$ | async)?.inclVAT | myCurrency: "1.2-2" }}
					</p>
				</div>
			</ng-template>
		</p-accordionTab>
		<p-accordionTab [(selected)]="panelStatus[2]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Order Notes</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="order-notes-container">
					<p>
						Please enter any pertinent information you would like us to know
						about your order.
					</p>
					<textarea
						[(ngModel)]="orderNotes"
						name="notes"
						id="order-notes"
					></textarea>
					<!-- <div class="shipping-costs">
            <p class="label shipping">Shipping Total (incl. VAT)</p>
            <p class="amt shipping">
              {{
                shippingAmount == null
                  ? "Select Method"
                  : (shippingAmount | currency: "ZAR":"symbol-narrow":"1.2-2")
              }}
            </p>
          </div> -->
				</div>
			</ng-template>
		</p-accordionTab>
	</p-accordion>
	<!-- <hr /> -->
	<div *ngIf="!hideCTA" class="cta">
		<button
			(click)="confirmOrder()"
			appButton
			[cta]="true"
			label="Place Order"
		></button>
	</div>
	<!-- <hr *ngIf="!hideCTA" /> -->
</div>

<!-- EU VERSION -->
<div *ngIf="currency == 'EUR'" class="container">
	<div class="cart-summary-title">Summary</div>
	<!-- <hr /> -->
	<p-accordion class="eu" [multiple]="true">
		<p-accordionTab class="eu" [(selected)]="panelStatus[0]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Items Cost</p>
					<p class="amount">
						{{ (itemsAmount$ | async)?.inclVAT | myCurrency: "1.2-2" }}
					</p>
				</div>
			</ng-template>
			<!-- <ng-template pTemplate="content">
        <div class="cart-items-total">
          <p class="label subtotal">Items Cost (excl. V.A.T.)</p>
          <p class="amt subtotal">
            {{
              itemsSubtotal | async | currency: "ZAR":"symbol-narrow":"1.2-2"
            }}
          </p>
          <p class="label vat">V.A.T. (15%)</p>
          <p class="amt vat">
            {{ itemsVAT | async | currency: "ZAR":"symbol-narrow":"1.2-2" }}
          </p>
          <p class="label subtotal">Total (incl. V.A.T.)</p>
          <p class="amt subtotal">
            {{
              (itemsAmount$ | async)?.inclVAT
                | currency: "ZAR":"symbol-narrow":"1.2-2"
            }}
          </p>
        </div>
      </ng-template> -->
		</p-accordionTab>
		<p-accordionTab [(selected)]="panelStatus[1]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Shipping</p>
					<p class="amount">
						{{
							selectedShippingMethod == shippingMethods[0]
								? "TBC"
								: selectedShippingMethod == shippingMethods[1]
								? "Free"
								: "Select Method"
						}}
					</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="shipping-stuff">
					<div class="shipping-method">
						<div class="shipping-method-label">
							Please Select A Shipping Method
						</div>
						<div class="shipping-method-select">
							<div class="method" *ngFor="let option of shippingMethods">
								<p-radioButton
									(onClick)="selectShippingMethod(option)"
									name="groupname"
									[value]="option"
									[label]="option.label"
									[(ngModel)]="selectedShippingMethod"
								></p-radioButton>
							</div>
						</div>
					</div>

					<!-- <div class="shipping-costs">
            <p class="label shipping">Shipping Total (incl. VAT)</p>
            <p class="amt shipping">
              {{
                shippingAmount == null
                  ? "Select Method"
                  : (shippingAmount | currency: "ZAR":"symbol-narrow":"1.2-2")
              }}
            </p>
          </div> -->
				</div>
			</ng-template>
		</p-accordionTab>
		<p-accordionTab [(selected)]="panelStatus[2]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Order Notes</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="order-notes-container">
					<p>
						Please enter any pertinent information you would like us to know
						about your order.
					</p>
					<textarea
						[(ngModel)]="orderNotes"
						name="notes"
						id="order-notes"
					></textarea>
					<!-- <div class="shipping-costs">
            <p class="label shipping">Shipping Total (incl. VAT)</p>
            <p class="amt shipping">
              {{
                shippingAmount == null
                  ? "Select Method"
                  : (shippingAmount | currency: "ZAR":"symbol-narrow":"1.2-2")
              }}
            </p>
          </div> -->
				</div>
			</ng-template>
		</p-accordionTab>
	</p-accordion>
	<!-- <hr /> -->
	<div *ngIf="!hideCTA" class="cta">
		<button
			(click)="confirmOrder()"
			appButton
			[cta]="true"
			label="Place Order"
		></button>
	</div>
	<!-- <hr *ngIf="!hideCTA" /> -->
</div>

<!-- EUR2 Verison -->
<div *ngIf="currency == 'EUR2'" class="container">
	<div class="cart-summary-title">Summary</div>
	<!-- <hr /> -->
	<p-accordion [multiple]="true">
		<p-accordionTab [(selected)]="panelStatus[0]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Order Total</p>
					<p class="amount">
						{{ (itemsAmount$ | async)?.inclVAT | myCurrency: "1.2-2" }}
					</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="cart-items-total">
					<p class="label subtotal">Items Cost</p>
					<p class="amt subtotal">
						{{ itemsSubtotal | async | myCurrency: "1.2-2" }}
					</p>
					<p class="label vat">Shipping Cost</p>
					<p *ngIf="!(Eur2FreeShipping | async)" class="amt vat">
						{{ 6.9 | myCurrency: "1.2-2" }}
					</p>
					<p *ngIf="Eur2FreeShipping | async" class="amt vat">Free</p>
					<p class="label subtotal">Total</p>
					<p class="amt subtotal">
						{{ (itemsAmount$ | async)?.inclVAT | myCurrency: "1.2-2" }}
					</p>
				</div>
			</ng-template>
		</p-accordionTab>
		<p-accordionTab [(selected)]="panelStatus[2]">
			<ng-template pTemplate="header">
				<div class="panel-title">
					<p class="description">Order Notes</p>
				</div>
			</ng-template>
			<ng-template pTemplate="content">
				<div class="order-notes-container">
					<p>
						Please enter any pertinent information you would like us to know
						about your order.
					</p>
					<textarea
						[(ngModel)]="orderNotes"
						name="notes"
						id="order-notes"
					></textarea>
					<!-- <div class="shipping-costs">
            <p class="label shipping">Shipping Total (incl. VAT)</p>
            <p class="amt shipping">
              {{
                shippingAmount == null
                  ? "Select Method"
                  : (shippingAmount | currency: "ZAR":"symbol-narrow":"1.2-2")
              }}
            </p>
          </div> -->
				</div>
			</ng-template>
		</p-accordionTab>
	</p-accordion>
	<!-- <hr /> -->
	<div *ngIf="!hideCTA" class="cta">
		<button
			(click)="confirmOrder()"
			appButton
			[cta]="true"
			label="Place Order"
		></button>
	</div>
	<!-- <hr *ngIf="!hideCTA" /> -->
</div>

<div *ngIf="screenSize.extraSmall || screenSize.small" class="mobile-fixed-btm">
	<button
		[cta]="true"
		(click)="confirmOrder()"
		appButton
		label="Place Order"
	></button>
</div>

<!-- <div class="cart-items-total">
  <h4 class="items-title">Items</h4>
  <p class="label subtotal">Subtotal (excl. V.A.T.)</p>
  <p class="amt subtotal">
    {{ 100 | currency: "ZAR":"symbol-narrow":"1.2-2" }}
  </p>
  <p class="label vat">V.A.T. (15%)</p>
  <p class="amt vat">{{ 15 | currency: "ZAR":"symbol-narrow":"1.2-2" }}</p>
  <p class="label subtotal">Total (incl. V.A.T.)</p>
  <p class="amt subtotal">
    {{ 115 | currency: "ZAR":"symbol-narrow":"1.2-2" }}
  </p>
</div>
<hr />
<div class="shipping-stuff">
  <h4 class="shipping-title">Shipping Costs</h4>
  <div class="shipping-method">
    <div class="shipping-method-label">Shipping Method</div>
    <div class="shipping-method-select">
      <p-dropdown
        placeholder="Select Shipping Method"
        [options]="shippingMethods"
        [(ngModel)]="selectedShippingMethod"
        [optionLabel]="'label'"
        (onChange)="selectShippingMethod($event)"
      ></p-dropdown>
    </div>
  </div>
  <form
    (submit)="onSubmitAddressForm()"
    [formGroup]="addressForm"
    *ngIf="true"
  >
    <div class="address-form">
      <p class="address-title">Please Enter Address</p>
      <div
        [ngClass]="{
          hasErrors: addressForm.controls['country'].invalid && submittedForm
        }"
        class="country"
      >
        <p-autoComplete
          [suggestions]="countrySuggestions"
          [dropdown]="true"
          field="label"
          formControlName="country"
          [dropdownMode]="'current'"
          (onDropdownClick)="countryQuery($event)"
          (completeMethod)="countryQuery($event)"
          placeholder="Country"
        ></p-autoComplete>
        <span
          *ngIf="addressForm.controls['country'].invalid && submittedForm"
          class="error"
          >Country Required</span
        >
      </div>
      <div
        [ngClass]="{
          hasErrors: addressForm.controls['city'].invalid && submittedForm
        }"
        class="city"
      >
        <input
          type="text"
          formControlName="city"
          placeholder="City"
          pInputText
        />
        <span
          *ngIf="addressForm.controls['city'].invalid && submittedForm"
          class="error"
          >City Required</span
        >
      </div>
      <div
        [ngClass]="{
          hasErrors:
            addressForm.controls['proviceOrState'].invalid && submittedForm
        }"
        class="province"
      >
        <p-autoComplete
          *ngIf="autocompleteProvices"
          [placeholder]="provinceLabel"
          field="label"
          formControlName="proviceOrState"
          (completeMethod)="provinceQuery($event)"
          [suggestions]="provinceSuggestions"
        ></p-autoComplete>
        <input
          type="text"
          *ngIf="!autocompleteProvices"
          formControlName="proviceOrState"
          [placeholder]="provinceLabel"
          pInputText
        />
        <span
          *ngIf="
            addressForm.controls['proviceOrState'].invalid && submittedForm
          "
          class="error"
          >{{ provinceLabel }} Required</span
        >
      </div>
      <div
        [ngClass]="{
          hasErrors:
            addressForm.controls['postalCode'].invalid && submittedForm
        }"
        class="postal"
      >
        <input
          type="text"
          formControlName="postalCode"
          placeholder="Post Code"
          pInputText
        />
        <span
          *ngIf="addressForm.controls['postalCode'].invalid && submittedForm"
          class="error"
          >Post Code Required</span
        >
      </div>
      <div class="cta">
        <button
          type="submit"
          appButton
          label="Estimate Cost"
          [mini]="true"
        ></button>
      </div>
    </div>
  </form>
  <div *ngIf="submittedForm && !showAddressForm" class="edit-address">
    <button appButton label="Edit Address" [mini]="true"></button>
  </div>
  <div class="shipping-costs">
    <p class="label shipping">Shipping Total (incl. VAT)</p>
    <p class="amt shipping">
      {{
        amount == null
          ? "Select Method"
          : (amount | currency: "ZAR":"symbol-narrow":"1.2-2")
      }}
    </p>
  </div>
</div> -->
