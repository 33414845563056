import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CartOverlayComponent } from './cart-overlay/cart-overlay.component';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeModule } from './home/home.module';
import { RedirectComponent } from './redirect/redirect.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		CartOverlayComponent,
		FooterComponent,
		FaqComponent,
		RedirectComponent,
	],
	imports: [
		//TODO: Add SharedModule & HomeModule
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		ScrollingModule,
		SharedModule,
		HomeModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule,
		AngularFireAuthGuardModule,
		AngularFirestoreModule.enablePersistence({
			synchronizeTabs: true,
		}),
		AngularFireFunctionsModule,
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {}
}
