import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NguiInviewModule } from '@ngui/common';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { ButtonDirective } from './button.directive';
import { CartLineItemComponent } from './components/cart-line-item/cart-line-item.component';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { CartComponent } from './components/cart/cart.component';
import {
  ProductCatalogueInfobarComponent,
} from './components/product-catalogue-infobar/product-catalogue-infobar.component';
import { ProductFilterInfoComponent } from './components/product-filter-info/product-filter-info.component';
import { ProductFilterComponent } from './components/product-filter/product-filter.component';
import { ProductImageGalleryComponent } from './components/product-image-gallery/product-image-gallery.component';
import { MyCurrencyPipe } from './my-currency.pipe';
import { PricePipe } from './price.pipe';

const sharedItems: any[] = [
	MenubarModule,
	ReactiveFormsModule,
	DialogModule,
	LayoutModule,
	ProgressSpinnerModule,
	InputTextModule,
	DynamicDialogModule,
	AutoCompleteModule,
	ProgressBarModule,
	NguiInviewModule,
	ButtonModule,
	ConfirmDialogModule,
	OverlayPanelModule,
	InputTextareaModule,
	CarouselModule,
	OrganizationChartModule,
	DropdownModule,
	PanelMenuModule,
	ToastModule,
	SplitButtonModule,
	RadioButtonModule,
	AccordionModule,
	TabViewModule,
	SidebarModule,
	SkeletonModule,
	PanelModule,
	MultiSelectModule,
	FormsModule,
	ScrollTopModule,
	CheckboxModule,
	ChipModule,
	HttpClientModule,
	TooltipModule,
	InputNumberModule,
	SelectButtonModule,
	DialogModule,
	ToolbarModule,
	MenuModule,
	TableModule,
];

@NgModule({
	declarations: [
		ProductCatalogueInfobarComponent,
		ProductFilterInfoComponent,
		ProductImageGalleryComponent,
		ButtonDirective,
		MyCurrencyPipe,
		ProductFilterComponent,
		CartComponent,
		CartLineItemComponent,
		CartSummaryComponent,
		PricePipe,
	],
	imports: [...sharedItems],
	exports: [
		...sharedItems,
		ProductImageGalleryComponent,
		ProductFilterInfoComponent,
		ButtonDirective,
		CartLineItemComponent,
		CartSummaryComponent,
		CartComponent,
		PricePipe,
		MyCurrencyPipe,
		ProductFilterComponent,
		ProductCatalogueInfobarComponent,
	],
})
export class SharedModule {}
