// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  cloudinary: {
    lifestyleUploadUrl: 'https://api.cloudinary.com/v1_1/alovesupreme/image/upload',
    productCardImageUrl:
      'https://res.cloudinary.com/alovesupreme/image/upload/t_product-card,f_auto/products',
    categoryCardImageUrl:
      'https://res.cloudinary.com/alovesupreme/image/upload/t_category_card,f_auto/categories',
    designCardImageUrl:
      'https://res.cloudinary.com/alovesupreme/image/upload/t_designV1,f_auto/designs',
    productGalleryImageUrl:
      'https://res.cloudinary.com/alovesupreme/image/upload/t_gallery-image,f_auto',
    lifestyleImagesUrl:
      'https://res.cloudinary.com/als-trial-webstore/image/list',
    galleryThumbnailImageUrl:
      'https://res.cloudinary.com/alovesupreme/image/upload/t_gallery-thumbnail,f_auto',
    loginPageImageUrl:
      'https://res.cloudinary.com/als-trial-webstore/image/upload/s--ofx-iBVq--/c_fit,h_1000,w_1160/v1613003991/lifestyle/LSP-000243.jpg',
  },
  firebase: {
    apiKey: 'AIzaSyD19ALCOR4pLN-7ixd5TrhZVluB0a9okbo',
    authDomain: 'alovesupreme-wholesale.firebaseapp.com',
    projectId: 'alovesupreme-wholesale',
    storageBucket: 'alovesupreme-wholesale.appspot.com',
    messagingSenderId: '163256033028',
    appId: '1:163256033028:web:2c0dd73c516255e9a3fa51',
    measurementId: 'G-CNFPLV9N21',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
