<div
	[ngClass]="{ invisible: !(showCartOverlay | async) }"
	class="contact-help"
	*ngIf="!onAdminPanel && (showContactHelp | async)"
>
	<div class="tag">Need Help</div>
	<div class="info">
		<p>
			If you are having any troubles using this website please call Melissa for
			assistance on 071 382 0348. If something is wrong you can also email
			<a href="mailto:webdev@alovesupreme.co.za?cc=melissa@alovesupreme.co.za"
				>webdev@alovesupreme.co.za</a
			>
			to report any issues.
		</p>
	</div>
</div>
<!-- <div *ngIf="onHomePage" class="background-image"></div> -->
<app-header *ngIf="!onAdminPanel" #appHeader> </app-header>
<router-outlet></router-outlet>
<div
	*ngIf="!onAdminPanel"
	[ngClass]="{ invisible: !(showCartOverlay | async) }"
	class="cart"
>
	<app-cart-overlay class=""></app-cart-overlay>
</div>

<div *ngIf="showLoadingOverlay | async" class="overlay">
	<div class="text">{{ loadingOverlayText | async }}</div>
	<p-progressSpinner
		[style]="{ width: '50px', height: '50px' }"
		styleClass="custom-spinner"
		strokeWidth="8"
		fill="#EEEEEE"
		animationDuration=".5s"
	></p-progressSpinner>
</div>
