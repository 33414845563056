import { Injectable } from '@angular/core';

import { Address, ShippingRate, ShippingRates } from './../models/address.model';
import { PriceInfo } from './../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ShippingService {
  rates: ShippingRate[] = ShippingRates;

  constructor() {}

  calculateRate(address: Address): PriceInfo | null {
    for (let rate of this.rates) {
      if (
        rate.matchCriteria.matchType == 'single' &&
        typeof rate.matchCriteria.criteria == 'string'
      ) {
        let criteria = rate.matchCriteria.criteria;
        let candidate = address[rate.matchCriteria.propName];
        if (criteria.toLowerCase() === candidate.trim().toLowerCase()) {
          return rate.priceInfo;
        }
      } else if (rate.matchCriteria.matchType == 'array') {
        let criteria: string[] = rate.matchCriteria.criteria as string[];

        let candidate = address[rate.matchCriteria.propName];
        for (let criterium of criteria) {
          if (criterium.toLowerCase() === candidate.trim().toLowerCase()) {
            return rate.priceInfo;
          }
        }
      }
    }

    return null;
  }
}
