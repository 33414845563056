import { OverlaysService } from './../services/overlays.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LayoutService, ScreenSize } from '../services/layout.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  screenSize!: ScreenSize;
  screenSub!: Subscription;

  constructor(private ls: LayoutService, private ovs: OverlaysService) {}

  ngOnInit() {
    this.screenSub = this.ls.screenSize$.subscribe((next) => {
      this.screenSize = next;
    });
    this.ovs.showContactHelp(false);
    this.ovs.showCartOverlay(false);
  }

  ngOnDestroy(): void {
    this.screenSub.unsubscribe();
  }
}
