import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { skipWhile, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { Product } from '../models/product.model';
import { DesignInfo } from './../models/product.model';
import { ProductService } from './product.service';

@Injectable({
	providedIn: "root",
})
export class DesignService {
	private designs$ = new BehaviorSubject<DesignInfo[]>([]);
	private activeDesigns$ = new BehaviorSubject<DesignInfo[]>([]);
	private productMasterList: Product[] = [];
	private pmlSub: Subscription;
	private designMasterList: DesignInfo[] = [];
	private loadedData: boolean = false;
	subsink = new SubSink();

	get designs() {
		return this.designs$.asObservable();
	}

	get activeDesigns() {
		return this.designs.pipe(map((des) => des.filter((di) => di.active)));
	}

	get inactiveDesigns() {
		return this.designs.pipe(map((des) => des.filter((d) => !d.active)));
	}

	constructor(
		private http: HttpClient,
		private ps: ProductService,
		private db: AngularFirestore
	) {
		this.pmlSub = this.ps.masterProducts.subscribe((masterProducts) => {
			this.productMasterList = masterProducts;
			this.loadDesignMasterList();
		});
	}

	ngOnDestroy(): void {
		this.pmlSub.unsubscribe();
		this.subsink.unsubscribe();
	}

	private loadDesignMasterList() {
		const sub = this.db
			.collection<DesignInfo>("/designs", (ref) => ref.orderBy("name"))
			.valueChanges()

			.subscribe((docs) => {
				this.designMasterList = docs;
				this.loadedData = true;
				this.pushDesigns();
			});
		this.subsink.add(sub);
	}

	private pushDesigns() {
		const cats = this.designMasterList;
		this.designs$.next(cats);
	}

	initialise(): void {
		if (!environment.production) {
		}
	}

	getNameByID(id: string) {
		const element = this.designMasterList.find((c) => c.id == id);
		if (!!element) {
			return element.name;
		} else {
			return "";
		}
	}

	get designsWithPhotos(): Observable<string[]> {
		return this.designs$.pipe(
			skipWhile((v) => !this.loadedData),
			take(1),
			map((designs) => {
				return designs
					.filter((d) => {
						if (!d.active) {
							return false;
						}
						if (!!d.photoExists) {
							return d.photoExists;
						} else {
							return false;
						}
					})
					.map((d) => d.id);
			})
		);
	}
}
