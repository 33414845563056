import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, skip } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { Currency } from '../models/order.model';
import { ScreenSize } from '../services/layout.service';
import { UserService } from '../services/user.service';
import { PriceInfo } from './../models/product.model';
import { LayoutService } from './../services/layout.service';
import { OrderService } from './../services/order.service';
import { OverlaysService } from './../services/overlays.service';

@Component({
  selector: 'app-cart-overlay',
  templateUrl: './cart-overlay.component.html',
  styleUrls: ['./cart-overlay.component.scss'],
})
export class CartOverlayComponent implements OnInit, OnDestroy {
  quantity: Observable<number>;
  orderTotal: Observable<PriceInfo>;
  justAddedItem: boolean = false;
  fullWidth: boolean = false;
  onOrderForm: boolean = false;
  subsink = new SubSink();
  showSidebar: boolean = false;
  screenSize!: ScreenSize;
  currency: Currency = 'ZAR';
  constructor(
    private os: OrderService,
    private router: Router,
    private ls: LayoutService,
    private ovs: OverlaysService,
    private us: UserService
  ) {
    this.subsink.add(
      this.ls.screenSize.subscribe((ss) => {
        this.screenSize = ss;
      })
    );
    this.subsink.add(
      this.us.applicableCurrency.subscribe((cur) => {
        this.currency = cur;
      })
    );
    const routeSub = this.router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((event) => {
        if (event.url.indexOf('wholesale/order') >= 0) {
          this.onOrderForm = true;
        } else {
          this.onOrderForm = false;
        }
      });
    this.quantity = this.os.itemQuantity;
    this.orderTotal = this.os.itemsTotal;
    this.subsink.add(
      this.os.items.pipe(skip(1)).subscribe((items) => {
        if (!this.onOrderForm) {
          if (items.length > 0) {
            this.justAddedItem = true;
            this.fullWidth = true;
            setTimeout(() => {
              this.justAddedItem = false;
            }, 1000);
            setTimeout(() => {
              this.fullWidth = false;
            }, 1600);
          }
        }
      })
    );
    this.subsink.add(routeSub);
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
  ngOnInit() {}

  gotoCart(): void {
    this.showSidebar = true;
  }

  sidebarShow(): void {
    this.ovs.cartSidebarOpened();
  }

  sidebarHide(): void {
    this.ovs.cartSidebarClosed();
  }
}
