import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PriceInfo } from 'src/app/models/product.model';
import { SubSink } from 'subsink';

import { Address, ShippingDetails } from '../models/address.model';
import { LineItem, OrderItems } from '../models/order.model';
import { Product } from '../models/product.model';
import { User } from '../models/user.model';
import { environment } from './../../environments/environment';
import { ShippingMethodType } from './../models/address.model';
import { Order, OrderWriteResult } from './../models/order.model';
import { ShippingService } from './shipping.service';
import { UserService } from './user.service';

const FREE_SHIPPING_THRESHOLD = 750;

@Injectable({
	providedIn: "root",
})
export class OrderService implements OnDestroy {
	private orderItems$ = new BehaviorSubject<OrderItems>({});
	private shippingDetails$ = new BehaviorSubject<ShippingDetails>(null);
	private qualifiesForFreeShipping$ = new BehaviorSubject<boolean>(false);
	private user: User = null;
	private currency: "ZAR" | "USD" | "EUR" | "GBP" | "EUR2" = "ZAR";
	private subsink = new SubSink();

	showFullCartOverlay = new BehaviorSubject<boolean>(false);

	get items() {
		return this.orderItems$.pipe(
			map((itemData) => {
				const keys = Object.keys(itemData);
				const lineItems: LineItem[] = [];
				keys.forEach((key) => lineItems.push(itemData[key]));
				return lineItems;
			})
		);
	}

	get orderItems() {
		return this.orderItems$.asObservable();
	}

	get shippingMethod() {
		return this.shippingDetails$.pipe(
			map((sd) => {
				if (!!sd) return sd.method;
				else return null;
			})
		);
	}

	get shippingAmount() {
		return this.shippingDetails$.pipe(
			map((sd) => {
				if (sd == null || sd == undefined) {
					return null;
				} else {
					return sd.amount;
				}
			})
		);
	}

	get itemQuantity() {
		return this.items.pipe(
			map((items) =>
				items.reduce<number>((prev, item) => prev + item.quantity, 0)
			)
		);
	}

	get itemsTotal(): Observable<PriceInfo> {
		return this.items.pipe(
			map((li) => {
				return li.reduce<PriceInfo>(
					(cv, pli) => {
						return {
							inclVAT: cv.inclVAT + pli.total.inclVAT,
							exclVAT: cv.exclVAT + pli.total.exclVAT,
						};
					},
					{
						inclVAT: 0,
						exclVAT: 0,
					}
				);
			})
		);
	}

	constructor(
		private ss: ShippingService,
		private db: AngularFirestore,
		private us: UserService,
		private functions: AngularFireFunctions
	) {
		const sub = this.us.user.subscribe((user) => {
			this.user = user;
			const region = user?.region;

			switch (region) {
				case "US":
					this.currency = "USD";
					break;
				case "EU":
					this.currency = "EUR";
					break;
				case "UK":
					this.currency = "GBP";
					break;
				case "EU2":
					this.currency = "EUR2";
					break;
				default:
					this.currency = "ZAR";
					break;
			}
		});
		this.subsink.add(sub);
	}

	ngOnDestroy(): void {
		this.subsink.unsubscribe();
	}

	selectLocalPickupShippingMethod(): void {
		this.shippingDetails$.next({
			method: ShippingMethodType.Pickup,
			amount: 0,
		});
	}

	selectCourier(address: Address) {
		const amount = this.ss.calculateRate(address);
		if (!!amount) {
			this.shippingDetails$.next({
				method: ShippingMethodType.Courier,
				amount: amount.inclVAT,
			});
		} else {
			this.shippingDetails$.next(null);
		}

		return amount;
	}

	increaseItemQuantity(product: Product, quantity: number = 1) {
		const currentOrder: OrderItems = JSON.parse(
			JSON.stringify(this.orderItems$.value)
		);
		const productID = product.id;
		if (!currentOrder[productID]) {
			this.setItemQuantity(product, quantity);
		} else {
			const currentQty = currentOrder[productID].quantity;
			const newQty = currentQty + quantity;
			this.setItemQuantity(product, newQty);
		}
	}

	setItemQuantity(product: Product, quantity: number) {
		const currentOrder: OrderItems = JSON.parse(
			JSON.stringify(this.orderItems$.value)
		);
		const productID = product.id;
		if (!environment.production) {
		}
		if (quantity <= 0) {
			this.removeItem(productID);
			return;
		} else if (!currentOrder[productID]) {
			let priceApplicable = product.priceInfo.zar;

			switch (this.currency) {
				case "USD":
					priceApplicable = product.priceInfo.usd;
					break;
				case "EUR":
					priceApplicable = product.priceInfo.eur;
					break;
				case "GBP":
					priceApplicable = product.priceInfo.gbp;
					break;
				case "EUR2":
					priceApplicable = product.priceInfo.eur2;
					break;
				default:
					priceApplicable = product.priceInfo.zar;
					break;
			}

			let total: PriceInfo = {
				inclVAT: priceApplicable.inclVAT * quantity,
				exclVAT: priceApplicable.exclVAT * quantity,
			};

			currentOrder[productID] = {
				product: product,
				unitAmount: priceApplicable,
				quantity: quantity,
				total: total,
			};
		} else {
			currentOrder[productID].quantity = quantity;
			currentOrder[productID].total = {
				inclVAT: quantity * currentOrder[productID].unitAmount.inclVAT,
				exclVAT: quantity * currentOrder[productID].unitAmount.exclVAT,
			};
		}

		this.orderItems$.next(currentOrder);
	}

	getSpecificLineItemObservable(
		productID: string
	): Observable<LineItem | undefined> {
		return this.orderItems$.pipe(map((itemsData) => itemsData[productID]));
	}

	removeItem(id: string) {
		const currentOrder: OrderItems = JSON.parse(
			JSON.stringify(this.orderItems$.value)
		);

		if (!environment.production) {
		}

		if (!!currentOrder[id]) {
			delete currentOrder[id];
		}

		if (!environment.production) {
		}

		this.orderItems$.next(currentOrder);
	}

	async placeOrder(
		items: OrderItems,
		total: PriceInfo,
		ownCourier: boolean,
		notes: string
	): Promise<OrderWriteResult> {
		if (!!this.user) {
			const type = this.user.admin ? "employees" : "customers";
			const userDocRef = this.db.doc(`/${type}/${this.user.uid}`);
			const oID = this.generateOrderID();
			const orderRef = userDocRef.collection("/orders").doc(oID);

			const now = new Date();
			let dateString = `${now.getFullYear().toString().substr(2, 2)}${(
				now.getMonth() + 1
			)
				.toString()
				.padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}`;

			const order: Order = {
				id: oID,
				client: this.user.displayName,
				date: dateString,
				ownCourier: ownCourier,
				items: items,
				currency: this.currency,
				total: total,
				completed: false,
				notes: notes,
			};
			try {
				await orderRef.set(order, {
					merge: false,
				});

				const sendEmail = this.functions.httpsCallable("OrderReceived");

				if (false) {
					return {
						success: true,
						id: oID,
						order: order,
					};
				} else {
					return sendEmail({ order: order, orderID: oID })
						.toPromise()
						.then((result) => {
							if (result.success) {
								if (!environment.production) {
								}
								this.orderItems$.next({});
								return {
									success: true,
									id: oID,
									order: order,
								};
							} else {
								if (!environment.production) {
								}
								return {
									success: false,
									error: result.error,
									order: order,
								};
							}
						});
				}
			} catch (err) {
				return {
					success: false,
					error: "Permission Denied",
					order: order,
				};
			}
		} else {
			return {
				success: false,
				error: "You must be logged in to place an order",
			};
		}
	}

	generateOrderID(): string {
		let result = "WSW";

		const now = new Date();
		let dateString = `${now.getFullYear().toString().substr(2, 2)}${(
			now.getMonth() + 1
		)
			.toString()
			.padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}`;

		result = result + "-" + dateString;

		const randomNumber = Math.floor(Math.random() * 1048576);
		const rid = randomNumber.toString(16);

		result = result + "-" + rid;
		return result.toUpperCase();
	}
}
