import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CategoryService } from 'src/app/services/category.service';
import { DesignService } from 'src/app/services/design.service';
import { RangeService } from 'src/app/services/range.service';

import { OverlaysService } from './services/overlays.service';
import { ProductService } from './services/product.service';
import { ShippingService } from './services/shipping.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'als-website';

  onHomePage = true;
  onAdminPanel = false;
  showCartOverlay: Observable<boolean>;
  showContactHelp: Observable<boolean>;
  showLoadingOverlay: Observable<boolean>;
  loadingOverlayText: Observable<string>;

  constructor(
    private router: Router,
    private ss: ShippingService,
    private auth: AngularFireAuth,
    private ps: ProductService,
    private rs: RangeService,
    private ds: DesignService,
    private cs: CategoryService,
    private us: UserService,
    private ovs: OverlaysService
  ) {
    this.ps.initialise();
    this.rs.initialise();
    this.ds.initialise();
    this.cs.initialise();
    this.us.initialise();
    this.ovs.initialise();

    this.showContactHelp = this.ovs.contact;
    this.showCartOverlay = this.ovs.cart;
    this.showLoadingOverlay = this.ovs.showLoadingOverlay;
    this.loadingOverlayText = this.ovs.loadingOverlayText;
  }

  ngOnInit(): void {
    this.router.events.subscribe((nextEvent) => {
      if (nextEvent instanceof NavigationEnd) {
        const url = nextEvent.url;
        this.onHomePage = url == '/' || url == '/home';
        this.onAdminPanel = url.includes('admin');
      }
    });
  }
}
