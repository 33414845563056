<div id="orderProcess" class="container">
  <div class="title heading-text">How Does This Work?</div>

  <div *ngFor="let step of orderProcess" [class]="'step-card ' + step.div">
    <div class="step">
      {{ step.step }}
    </div>
    <div class="info">
      {{ step.info }}
    </div>
    <div class="icon">
      <i [class]="'pi ' + step.icon"></i>
    </div>
  </div>

  <div class="arrow ot">
    <i class="pi pi-arrow-right"></i>
  </div>
  <div class="arrow tt">
    <i class="pi pi-arrow-right"></i>
  </div>
  <div class="arrow tf">
    <i class="pi pi-arrow-down"></i>
  </div>
  <div class="arrow ff">
    <i class="pi pi-arrow-down"></i>
  </div>
  <div class="arrow fs">
    <i class="pi pi-arrow-left"></i>
  </div>
  <div class="arrow ss">
    <i class="pi pi-arrow-left"></i>
  </div>
</div>
