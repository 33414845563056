import { Currency } from './order.model';
import { Product } from './product.model';

export enum PriceType {
  EXCL_VAT,
  INCL_VAT,
}

export interface IFilter {
  check: (product: Product) => boolean;
}

export class CategoryFilter implements IFilter {
  constructor(public categoryIDs: string[]) {}

  check(product: Product): boolean {
    if (this.categoryIDs.length == 0) {
      return true;
    } else {
      const idsToCheck = product.categories.map((m) => m.id);

      for (const id of idsToCheck) {
        if (this.categoryIDs.indexOf(id) >= 0) {
          return true;
        }
      }
      return false;
    }
  }
}

export class RangeFilter implements IFilter {
  constructor(public rangeIDs: string[]) {}

  check(product: Product): boolean {
    if (this.rangeIDs.length == 0) {
      return true;
    } else {
      return this.rangeIDs.indexOf(product.range.id) >= 0;
    }
  }
}

export class DesignFilter implements IFilter {
  constructor(public designIDs: string[]) {}

  check(product: Product): boolean {
    if (this.designIDs.length == 0) {
      return true;
    } else {
      return this.designIDs.indexOf(product.design.id) >= 0;
    }
  }
}

export class PriceFilter implements IFilter {
  constructor(
    public currency: Currency,
    public min?: number,
    public max?: number,
    public priceType: PriceType = PriceType.EXCL_VAT
  ) {}

  check(product: Product): boolean {
    let price: number | undefined;
    let priceApplicable = product.priceInfo.zar;

    switch (this.currency) {
      case 'USD':
        priceApplicable = product.priceInfo.usd;
        break;
      case 'EUR':
        priceApplicable = product.priceInfo.eur;
        break;
      case 'GBP':
        priceApplicable = product.priceInfo.gbp;
        break;
      default:
        priceApplicable = product.priceInfo.zar;
        break;
    }

    switch (this.priceType) {
      case PriceType.INCL_VAT:
        price = priceApplicable.inclVAT;
        break;
      case PriceType.EXCL_VAT:
        price = priceApplicable.exclVAT;
        break;
      default:
        price = priceApplicable.inclVAT;
        break;
    }

    if (!price) {
      return false;
    } else {
      if (this.min && this.max) {
        return price >= this.min && price <= this.max;
      } else if (this.min) {
        return price >= this.min;
      } else if (this.max) {
        return price <= this.max;
      } else {
        return true;
      }
    }
  }
}

type filterObject = {
  categoryFilter: CategoryFilter;
  rangeFilter: RangeFilter;
  designFilter: DesignFilter;
  priceFilter: PriceFilter;
};

export class ProductFilter {
  get hasFilters() {
    return (
      this.categoryFilter.categoryIDs.length > 0 ||
      this.rangeFilter.rangeIDs.length > 0 ||
      this.designFilter.designIDs.length > 0 ||
      this.priceFilter.max != undefined ||
      this.priceFilter.min != undefined
    );
  }

  get hasClearableFilter() {
    return (
      this.categoryFilter.categoryIDs.length > 0 ||
      this.rangeFilter.rangeIDs.length > 0 ||
      this.designFilter.designIDs.length > 0 ||
      this.priceFilter.max != undefined ||
      this.priceFilter.min != undefined
    );
  }

  constructor(
    public categoryFilter: CategoryFilter,
    public rangeFilter: RangeFilter,
    public designFilter: DesignFilter,
    public priceFilter: PriceFilter
  ) {}

  static fromObject(obj: filterObject): ProductFilter {
    return new ProductFilter(
      obj.categoryFilter,
      obj.rangeFilter,
      obj.designFilter,
      obj.priceFilter
    );
  }

  static noFilter(): ProductFilter {
    return new ProductFilter(
      new CategoryFilter([]),
      new RangeFilter([]),
      new DesignFilter([]),
      new PriceFilter('ZAR')
    );
  }

  check(product: Product): boolean {
    return (
      this.categoryFilter.check(product) &&
      this.rangeFilter.check(product) &&
      this.designFilter.check(product) &&
      this.priceFilter.check(product)
    );
  }
}
