<div class="container">
  <div class="product-quantity">{{ numProducts }} Products</div>
  <div class="filters">
    <app-product-filter-info></app-product-filter-info>
  </div>
  <div
    *ngIf="wholesaleClient && saClient"
    [ngClass]="{ mobile: screenSize.extraSmall || screenSize.small }"
    class="price-type"
  >
    <p>View Prices</p>
    <p-selectButton
      [options]="priceOptions"
      [(ngModel)]="selectedPriceOption"
      (onChange)="priceChange()"
      optionLabel="label"
    ></p-selectButton>
  </div>
</div>
