<div class="content">
	<p class="info-heading">
		<strong>Your full order, without the wait.</strong>
	</p>
	<div class="panel info">
		<p class="redirect-info">
			The start of a new year is a great time to reflect on the previous one -
			what worked, what didn’t, and what we can do to improve. Our goal is to
			get your orders to you faster, without stock on back order. After a
			strenuous yet rewarding 2021, we realise that we need to simplify our
			systems and be more efficient in 2022. Moving forward, all wholesale
			orders are to be placed on our retail website which shows “live” stock;
			meaning we can pack your orders in full, within days of receiving them. No
			more part-orders or long delays - You’ll be ordering directly from the
			stock that is currently available!
		</p>
		<p class="redirect-info">
			We may relaunch our wholesale website again in the future, but for now,
			we’re streamlining our processes in order to better serve you.
		</p>
		<a href="https://alovesupreme.co.za" target="_blank"
			><button appButton label="Go To Retail Site"></button
		></a>
	</div>
	<p class="steps-heading">The New Order Process:</p>
	<div class="panel steps">
		<ol>
			<li>
				Go to our retail website at
				<a target="_blank" href="https://alovesupreme.co.za"
					>www.alovesupreme.co.za</a
				>
				(there is no need to login).
			</li>
			<li>
				Add the products you want to your cart (all wholesale prices ex VAT will
				be 50% of the displayed retail prices).
			</li>
			<li>
				<span class="ul">National customers:</span> Go to your basket and select
				your preferred shipping method (Door-to door courier or Local
				pickup).<br /><br />
				<span class="ul">International customers:</span> Go to your basket and
				select the Local Pickup Shipping method. Your sales rep will calculate
				your shipping costs and add this to your invoice. <br /><br />
				**Please Note: Free shipping for orders over R750 only applies to retail
				customers.
			</li>
			<li>
				Enter the Coupon Code: Wholesale2022! to ensure you are charged
				wholesale prices. Please remember that our MOQ is R2500 ex VAT at
				wholesale pricing.
			</li>
			<li>
				<span class="ul">National customers:</span> Proceed to checkout,
				complete your billing details and select your preferred payment method.
				<br /><br />
				<span class="ul">International customers:</span> Proceed to checkout,
				complete your billing details and please select DIRECT BANK TRANSFER as
				your payment method.
			</li>
			<li>Place Order and wait for your wholesale rep to contact you!</li>
			<li>
				Once you have received your invoice please send through proof of payment
				to
				<a href="mailto:accounts@alovesupreme.co.za"
					>accounts@alovesupreme.co.za</a
				>
				and your wholesale rep and we will dispatch your order.<br /><br />
				<span class="ul">National customers: </span>If you paid via credit card
				at check out, there is no need to send a proof of payment to us.
			</li>
		</ol>
		<p>
			If you have any further queries please contact one of our wholesale reps
			for further assistance:
		</p>
		<p>
			National Orders: Melissa
			<a href="mailto:melissa@alovesupreme.co.za">melissa@alovesupreme.co.za</a>
		</p>
		<p>
			International Orders: Leanne
			<a href="leanne@alovesupreme.co.za">leanne@alovesupreme.co.za</a>
		</p>
	</div>
</div>
