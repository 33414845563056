<div class="landing hero-image">
	<div class="landing-title big-heading heading-text">
		Welcome to our Wholesale Portal
	</div>
	<div class="info info-text">
		<p>
			Looking for beautifully designed local products that will fly off your
			shelves? <br />
			<br />
			Add some ease to your life with our online ordering website. As easy as an
			online shopping spree view products, add them to your cart and place your
			order!<br />
			<br />
			A quick reminder about our two ordering options.
		</p>
	</div>
</div>
