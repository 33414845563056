import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { CategoryInfo, Product } from './../models/product.model';
import { ProductService } from './product.service';

@Injectable({
	providedIn: "root",
})
export class CategoryService implements OnDestroy {
	private categories$ = new BehaviorSubject<CategoryInfo[]>([]);
	private productMasterList: Product[] = [];
	private pmlSub: Subscription;
	private categoryMasterList: CategoryInfo[] = [];

	subsink = new SubSink();

	get categories() {
		return this.categories$.asObservable();
	}

	constructor(private ps: ProductService, private db: AngularFirestore) {
		this.pmlSub = this.ps.masterProducts.subscribe((masterProducts) => {
			this.productMasterList = masterProducts;
			this.loadCategoryMasterList();
		});
	}

	ngOnDestroy(): void {
		this.pmlSub.unsubscribe();
		this.subsink.unsubscribe();
	}

	initialise(): void {
		if (!environment.production) {
		}
	}

	private loadCategoryMasterList() {
		const sub = this.db
			.collection<CategoryInfo>("/categories", (ref) => ref.orderBy("name"))
			.valueChanges()

			.subscribe((docs) => {
				this.categoryMasterList = docs;
				this.pushCategories();
			});

		this.subsink.add(sub);
	}

	private pushCategories() {
		const cats = this.categoryMasterList;
		this.categories$.next(cats);
	}

	childCategories(id: string): Observable<CategoryInfo[]> {
		return this.categories.pipe(
			skipWhile((next) => next.length == 0),
			map((next) => {
				let cats: CategoryInfo[];
				if (!environment.production) {
				}
				if (id == "root") {
					cats = next.filter((c) => !c.parentID);
				} else {
					cats = next.filter((c) => c.parentID === id);
					if (!environment.production) {
					}
				}
				return cats;
			})
		);
	}

	getNameByID(id: string) {
		const element = this.categoryMasterList.find((c) => c.id == id);
		if (!!element) {
			return element.name;
		} else {
			return "";
		}
	}

	getCategoryAncestors(child: CategoryInfo): CategoryInfo[] {
		const parentId = child.parentID;
		let hasParent = !!parentId;
		const results: CategoryInfo[] = [];
		if (!!hasParent) {
			const parent = this.categoryMasterList.find((c) => c.id == parentId);
			if (!!parent) {
				const parentAncestors = this.getCategoryAncestors(parent);
				results.push(parent);
				results.push(...parentAncestors);
			}
		}
		return results;
	}
}
