<div class="container">
	<div class="op op1">
		<h3 class="op">Option 1: Orders via the RETAIL website</h3>
		<p class="op">
			Order from stock on hand! Simply select your shipping fee, add your coupon
			code, and pay immediately or wait on an invoice and EFT.
			<br /><br />
			We will process this order within 2-3 business days.
		</p>
	</div>
	<div class="op op2">
		<h3 class="op">Option 2: Orders via the WHOLESALE website</h3>
		<p class="op">
			If you would prefer to place a larger order and don’t mind an extended
			lead time simply order via the link below. <br /><br />
			If stock is on order it will take 2-3 weeks to process. <br />
			<br />
			If we are needing to print fabric, it will take 4-6 weeks.
		</p>
	</div>
	<div class="step1">
		<h3 class="op">Follow the steps below:</h3>
		<ol>
			<li>
				Select the button above to access our retail website (there is no need
				to login).
			</li>
			<li>
				Add products to your cart (all wholesale prices ex VAT will be 50% of
				the displayed retail prices).
			</li>
			<li>
				<span style="text-decoration: underline">National customers:</span> Go
				to your basket and select your preferred shipping method (Door-to-door
				courier or Local pickup). <br />
				<span style="text-decoration: underline">International customers:</span>
				Go to your basket and select the Local Pickup Shipping method. Your
				sales rep will calculate your shipping costs and add this to your
				invoice. <br /><br />
				**Please Note: Free shipping for orders over R750 only applies to retail
				customers.
			</li>
			<li>
				Enter the coupon code from your wholesale rep to ensure you are charged
				wholesale prices. Please remember that our MOQ is R2500 ex VAT at
				wholesale pricing.
			</li>
			<li>
				<span style="text-decoration: underline">National customers:</span>
				Proceed to checkout, complete your billing details, and select your
				preferred payment method. courier or Local pickup). <br />
				<span style="text-decoration: underline">International customers:</span>
				Proceed to checkout, complete your billing details and please select
				DIRECT BANK TRANSFER as your payment method.
			</li>
			<li>Place order and wait for your wholesale rep to contact you!</li>
		</ol>
	</div>
	<div class="step2">
		<h3 class="op">Follow the steps below:</h3>
		<ol>
			<li>Select the button above to order via our wholesale website.</li>
			<li>
				Select whether you would like to browse by product, category, design or
				via our order form.
			</li>
			<li>
				At checkout, select whether you would like to use your own courier and
				add any necessary notes to your order.
			</li>
			<li>Place order and wait for your wholesale rep to contact you!</li>
			<li>
				Once your order is complete, we will invoice you. Upon receiving proof
				of payment, we will assign a courier.<br /><br />
				**Please Note: Our MOQ is R2500 ex VAT. Any orders placed under this
				amount will be charged a small admin fee of R100.
			</li>
		</ol>
	</div>

	<div class="cta cta1">
		<a target="_blank" href="https://alovesupreme.co.za/"
			><button
				appButton
				[cta]="true"
				[hoverEffect]="true"
				label="Shop Retail Website"
			></button
		></a>
	</div>
	<div class="cta cta2">
		<a [routerLink]="['/shop', 'options']"
			><button
				appButton
				[cta]="true"
				[hoverEffect]="true"
				label="Shop Wholesale Website"
			></button
		></a>
	</div>
</div>
