import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Currency, Order, OrderHistory } from '../models/order.model';
import { LoginDetails, User, UserInfo } from './../models/user.model';

@Injectable({
	providedIn: "root",
})
export class UserService {
	public user$ = new BehaviorSubject<User>(null);

	get user() {
		return this.user$.asObservable();
	}

	get applicableCurrency() {
		return this.user.pipe(
			map((user) => {
				const region = user?.region;
				let currency: Currency = "ZAR";
				switch (region) {
					case "US":
						currency = "USD";
						break;
					case "EU":
						currency = "EUR";
						break;
					case "UK":
						currency = "GBP";
						break;
					case "EU2":
						currency = "EUR2";
						break;
					default:
						currency = "ZAR";
						break;
				}

				return currency;
			})
		);
	}

	get userAuthenticated() {
		return this.user$.pipe(map((u) => !!u));
	}

	get userIsAdmin() {
		return this.user$.pipe(
			map((u) => {
				return !!u?.admin;
			})
		);
	}

	constructor(
		private auth: AngularFireAuth,
		private router: Router,
		private db: AngularFirestore
	) {
		this.auth.authState.subscribe(async (user) => {
			if (!environment.production) {
			}
			if (!!user) {
				const claims = (await user.getIdTokenResult()).claims;
				const admin = claims.type == "employee";
				const region = claims.region || "SA";
				const info = {
					email: user.email || "",
					displayName: user.displayName || "",
					uid: user.uid,
					admin: admin,
					region: region,
				};

				if (!environment.production) {
				}
				this.user$.next(info);
			} else {
				this.user$.next(null);
			}
		});
	}

	initialise(): void {
		if (environment.production) {
		}
	}

	async loginUser(loginDetails: LoginDetails): Promise<UserInfo | null> {
		try {
			await this.auth.setPersistence("local");
			const user = await this.auth.signInWithEmailAndPassword(
				loginDetails.email,
				loginDetails.password
			);
			if (user.user != null) {
				const claims = (await user.user.getIdTokenResult()).claims;
				const admin = claims.type == "employee";
				const region = claims.region || "SA";
				const changePW = claims.resetPassword || false;

				const userInfo: UserInfo = {
					email: user.user?.email || "",
					displayName: user.user.displayName || "",
					uid: user.user.uid,
					admin: admin,
					mustChangePassword: changePW,
					region: region,
				};

				if (!environment.production) {
				}
				this.user$.next(userInfo);
				return userInfo;
			}
			return null;
		} catch (err) {
			this.user$.next(null);
			return null;
		}
	}

	logoutUser(): void {
		this.auth.signOut().then(() => {
			this.router.navigate(["/", "home"]);
		});
	}

	async getUserOrders(): Promise<OrderHistory> {
		if (this.user$.value != null) {
			const user = this.user$.value;
			const type = user.admin ? "employees" : "customers";
			const path = `/${type}/${user.uid}/orders`;

			if (!environment.production) {
			}

			const orderRef = this.db.collection<Order>(path);

			const results = await orderRef
				.get()
				.pipe(
					take(1),
					map((qs) => {
						const orderHistory: OrderHistory = {};
						qs.forEach((qs) => {
							orderHistory[qs.id] = qs.data();
						});
						return orderHistory;
					})
				)
				.toPromise();

			return results;
		} else {
			return {};
		}
	}

	async changeUserPassword(password: string) {
		const user = await this.auth.currentUser;
		if (!!user) {
			try {
				await user.updatePassword(password);
				return true;
			} catch (err) {
				if (!environment.production) {
				}
				return false;
			}
		}
		return false;
	}

	changeRegion(region: "US" | "EU" | "SA" | "EU2") {
		const user = this.user$.value;

		if (!!user && user.admin) {
			const update: Partial<UserInfo> = {
				region: region,
			};

			this.user$.next({
				...user,
				...update,
			});
		}
	}
}
