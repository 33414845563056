<div class="container">
  <div class="headers">
    <div class="details">Product Details</div>
    <div class="qty">Quantity</div>
    <div class="total">Total</div>
    <div (click)="editClicked()" class="edit">
      <i
        class="pi"
        [ngClass]="{ 'pi-pencil': !editing, 'pi-check': editing }"
      ></i>
    </div>
  </div>
  <hr />
  <div class="items" *ngFor="let item of items">
    <app-cart-line-item
      [editing]="editing"
      [lineItem]="item"
      [mini]="mini"
    ></app-cart-line-item>
  </div>
</div>
