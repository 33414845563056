import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { LayoutService, ScreenSize } from '../../services/layout.service';

interface RangePanelInfo {
	name: string;
	imageUrl: string;
}

@Component({
	selector: "app-ranges-showcase",
	templateUrl: "./ranges-showcase.component.html",
	styleUrls: ["./ranges-showcase.component.scss"],
})
export class RangesShowcaseComponent implements OnInit, OnDestroy {
	currentIndex = "1";
	startedCarousel = false;
	cars = [1, 2, 3, 4];

	ranges = [
		{
			id: "SD",
			class: "sd",
			name: "Sunny Daze",
			imageUrl: "/ranges/SD",
		},
		{ id: "CLA", class: "cla", name: "Classic", imageUrl: "/ranges/CLA" },
		{ id: "WAH", class: "wah", name: "Wild At Heart", imageUrl: "/ranges/WAH" },
	];

	screenSize!: ScreenSize;
	screenSub!: Subscription;

	@ViewChild("container", { static: false }) container!: ElementRef;

	constructor(private renderer: Renderer2, private ls: LayoutService) {
		this.screenSub = this.ls.screenSize.subscribe(
			(ss) => (this.screenSize = ss)
		);
	}
	ngOnDestroy(): void {
		this.screenSub.unsubscribe();
	}

	ngOnInit(): void {
		this.screenSub = this.ls.screenSize$.subscribe((next) => {
			this.screenSize = next;
		});
	}

	onIntersection(event: IntersectionObserverEntry): void {
		if (event.isIntersecting && !this.startedCarousel) {
			this.startedCarousel = true;
		}
	}

	scroll(event: any): void {
		const index: number = event.page;
	}
}
