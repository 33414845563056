<div appFullscreen class="story fullscreenSection">
  <img
    src="https://res.cloudinary.com/alovesupreme/image/upload/t_story_image_l,f_auto/assets/story.jpg"
    alt="Owners of A Love Supreme, Leanne and Ryan Botha"
  />
  <div class="story-content">
    <div *ngIf="screenSize.large" class="story-title heading-text title">
      Our Story
    </div>
    <div class="info info-text">
      <p>We create beautiful things that put the fun back into functional.</p>

      <p>
        From tote bags to towels, oven mitts to cushions, we like to think that
        every one of our products is love made visible. Because we really love
        what we do. It’s why we put in the kind of extra attention-to-detail and
        devotion you’d expect from people who don’t have any other hobbies — all
        so that you can find gifts you love for the people you love (and for
        yourself of course).
      </p>

      <p>
        Launched in 2014 as a daring detour from their graphic design agency,
        husband and wife team, Ryan and Leanne Botha, have infused aLoveSupreme
        with their inspired verve, playful sense of humour and non-negotiable
        commitment to quality every step of the way.
      </p>

      <p>
        Bold, bright and always original, hand-drawn illustrations are the hub
        of every aLoveSupreme piece—adding some magic to a world besieged with
        ordinary, run-of-the-mill products.
      </p>

      <p>
        Embracing all things stationery, aLoveSupreme has created a classy
        collection of note books, wrapping paper, gift bags, note cards and gift
        tags.
      </p>

      <p>
        Branching off into the world of homeware, the range includes cushion
        covers, tea towels, napkins, trays, melamine and paper placemats.
        aLoveSupreme’s plucky prints are also available as tote bags, pouches,
        laptop sleeves and towels.
      </p>

      <p>
        All aLoveSupreme products are proudly designed and produced in South
        Africa.
      </p>
    </div>
  </div>

  <div *ngIf="!screenSize.large" class="story-title section-heading">
    Our Story
  </div>
</div>
