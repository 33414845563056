import { Component, Input, OnInit } from '@angular/core';
import { LineItem } from 'src/app/models/order.model';
import { OrderService } from 'src/app/services/order.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
	selector: "app-cart",
	templateUrl: "./cart.component.html",
	styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
	items: LineItem[] = [];
	editing: boolean = false;
	@Input() mini: boolean = false;

	subsink = new SubSink();
	constructor(private os: OrderService) {
		this.subsink.add(
			this.os.items.subscribe((li) => {
				if (!environment.production) {
				}
				this.items = li;
			})
		);
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subsink.unsubscribe();
	}

	editClicked() {
		this.editing = !this.editing;
	}
}
