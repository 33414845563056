import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { Currency, OrderItems } from 'src/app/models/order.model';
import { PriceInfo } from 'src/app/models/product.model';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { UserService } from '../../../services/user.service';
import { ShippingMethod, ShippingMethodType } from './../../../models/address.model';
import { LayoutService, ScreenSize } from './../../../services/layout.service';
import { OrderService } from './../../../services/order.service';
import { OverlaysService } from './../../../services/overlays.service';

@Component({
	selector: "app-cart-summary",
	templateUrl: "./cart-summary.component.html",
	styleUrls: ["./cart-summary.component.scss"],
	providers: [MessageService, ConfirmationService],
})
export class CartSummaryComponent implements OnInit, OnDestroy {
	@Input() hideCTA: boolean = false;

	shippingMethods: ShippingMethod[] = [
		{
			label: "Use our courier and get quoted for shipping costs",
			type: ShippingMethodType.Courier,
		},
		{
			label: "Have your own courier pick order up",
			type: ShippingMethodType.Pickup,
		},
	];

	selectedShippingMethod!: ShippingMethod;
	selectedShippingMethod$: BehaviorSubject<ShippingMethodType | null> =
		new BehaviorSubject<ShippingMethodType | null>(null);
	itemsAmount$: Observable<PriceInfo>;
	items$: Observable<OrderItems>;
	subsink = new SubSink();
	screenSize!: ScreenSize;
	panelStatus = [true, false, false];

	placingOrder = false;
	orderSubtotal!: number;
	currency: Currency = "ZAR";

	orderNotes: string = "";

	get itemsSubtotal() {
		return this.itemsAmount$.pipe(
			map((t) => t.exclVAT),
			map((p) => {
				if (this.currency == "USD") {
					return p - 30;
				} else if (this.currency == "EUR2" && p - 6.9 < 75) {
					return p - 6.9;
				} else {
					return p;
				}
			}),
			tap((st) => (this.orderSubtotal = st))
		);
	}

	get itemsVAT() {
		return this.itemsAmount$.pipe(map((t) => t.inclVAT - t.exclVAT));
	}

	get shippingAmount(): Observable<string> {
		return this.selectedShippingMethod$.pipe(
			map((sm) => {
				if (sm === null) {
					return "Select Method";
				} else if (sm === ShippingMethodType.Courier) {
					return "TBC";
				} else {
					return "Free";
				}
			})
		);
	}

	get Eur2FreeShipping(): Observable<boolean> {
		return this.os.itemsTotal.pipe(
			map((pi) => {
				return pi.exclVAT >= 75;
			})
		);
	}

	constructor(
		private os: OrderService,
		private ms: MessageService,
		private router: Router,
		private ls: LayoutService,
		private ovs: OverlaysService,
		private confirm: ConfirmationService,
		private us: UserService
	) {
		this.itemsAmount$ = this.os.itemsTotal;

		this.items$ = this.os.orderItems;
		this.subsink.add(
			this.ls.screenSize.subscribe((ss) => (this.screenSize = ss))
		);
		this.subsink.add(
			this.us.applicableCurrency.subscribe((cur) => {
				this.currency = cur;
				if (cur == "USD") {
					this.itemsAmount$ = this.os.itemsTotal.pipe(
						map((pi) => {
							return {
								exclVAT: pi.exclVAT + 30,
								inclVAT: pi.inclVAT + 30,
							};
						})
					);
				}
				if (cur == "EUR") {
					this.panelStatus = [false, true];
				}
				if (cur == "EUR2") {
					this.itemsAmount$ = this.os.itemsTotal.pipe(
						skipWhile((pi) => pi == null || pi == undefined),
						map((pi) => {
							if (pi.exclVAT >= 75) {
								return pi;
							} else {
								return {
									exclVAT: pi.exclVAT + 6.9,
									inclVAT: pi.inclVAT + 6.9,
								};
							}
						})
					);
				}
			})
		);
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.subsink.unsubscribe();
	}

	selectShippingMethod(value: any) {
		this.panelStatus[1] = false;
	}

	async placeOrder() {
		if (!!this.selectedShippingMethod || this.currency != "ZAR") {
			this.ovs.activateLoadingOverlay("Placing Order");
			combineLatest([this.itemsAmount$, this.items$])
				.pipe(take(1))
				.subscribe(async (value) => {
					const total = value[0];
					const items = value[1];
					if (!environment.production) {
					}
					const ownCourier =
						this.currency == "ZAR"
							? this.selectedShippingMethod.type == ShippingMethodType.Pickup
							: this.currency == "EUR";
					const result = await this.os.placeOrder(
						items,
						total,
						ownCourier,
						this.orderNotes
					);
					if (!environment.production) {
					}
					if (!result.success) {
						this.ovs.deactivateLoadingOverlay();
						this.ms.add({
							summary: "Placing Order Failed",
							severity: "error",
							detail: result.error || "Internal Error",
							key: "tr",
							sticky: true,
							closable: true,
						});
					} else {
						this.ovs.deactivateLoadingOverlay();

						this.router.navigate(["/", "user", "order-confirm"], {
							state: {
								id: result.id,
								order: result.order,
							},
						});
					}
				});
		} else {
			this.ms.add({
				severity: "warn",
				summary: "Please select a shipping method",
				key: "tr",
				sticky: false,
				life: 2000,
			});
			this.panelStatus[1] = true;
		}
	}

	async confirmOrder() {
		if (this.currency == "ZAR" && this.orderSubtotal < 2500) {
			this.confirm.confirm({
				header: "Confirm Order",
				message:
					" Your order is below the order minimum of R2500 ex VAT. Would you like to place your order anyway?",
				acceptLabel: "Add Items",
				rejectLabel: "Proceed Anyway",
				rejectIcon: "pi pi-exclamation-triangle",
				acceptIcon: "pi pi-plus-circle",
				reject: async () => {
					await this.placeOrder();
				},
				accept: () => {
					if (this.us.user$.value?.region == "EU2") {
						this.router.navigate(["/", "shop", "products"]);
					} else {
						this.router.navigate(["/", "home"]);
					}
				},
				dismissableMask: false,
			});
		} else {
			await this.placeOrder();
		}
	}
}
