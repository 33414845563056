import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MultiCurrencyPriceInfo } from '../models/product.model';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';

@Pipe({
	name: "price",
})
export class PricePipe implements PipeTransform {
	public user$: BehaviorSubject<User>;
	constructor(private us: UserService) {
		this.user$ = this.us.user$;
	}

	transform(
		value: MultiCurrencyPriceInfo,
		digits?: string,
		inclusive: boolean = false
	): string | null {
		let cp: CurrencyPipe = new CurrencyPipe("en-ZA", "ZAR");
		const userRegion = this.user$.value?.region;
		if (!userRegion) {
			let amt = inclusive ? value.zar.inclVAT : value.zar.exclVAT;
			let di = digits || (amt % 1 == 0 ? "1.0-0" : "1.2-2");
			return cp.transform(amt, "ZAR", "symbol-narrow", di);
		} else {
			let currencyCode = "ZAR";
			let amount: number = inclusive ? value.zar.inclVAT : value.zar.exclVAT;
			switch (userRegion) {
				case "US":
					currencyCode = "USD";
					amount = inclusive ? value.usd.inclVAT : value.usd.exclVAT;
					break;
				case "EU":
					currencyCode = "EUR";
					amount = inclusive ? value.eur.inclVAT : value.eur.exclVAT;
					break;
				case "UK":
					currencyCode = "GBP";
					amount = inclusive ? value.gbp.inclVAT : value.gbp.exclVAT;
					break;
				case "EU2":
					currencyCode = "EUR";
					amount = inclusive ? value.eur2.inclVAT : value.eur2.exclVAT;
					break;
				default:
					currencyCode = "ZAR";
					break;
			}

			let di = digits || (amount % 1 == 0 ? "1.0-0" : "1.2-2");
			return cp.transform(amount, currencyCode, "symbol-narrow", di);
		}
	}
}
