import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { Product } from '../models/product.model';
import { RangeInfo } from './../models/product.model';
import { ProductService } from './product.service';

@Injectable({
	providedIn: "root",
})
export class RangeService implements OnDestroy {
	private activeRanges$ = new BehaviorSubject<RangeInfo[]>([]);
	private allRanges$ = new BehaviorSubject<RangeInfo[]>([]);
	private productMasterList: Product[] = [];
	private rangeMasterList: RangeInfo[] = [];
	private loadedData: boolean = false;

	subsink = new SubSink();

	get activeRanges() {
		return this.activeRanges$.asObservable();
	}

	get allRanges() {
		return this.allRanges$.asObservable();
	}

	constructor(
		private http: HttpClient,
		private ps: ProductService,
		private db: AngularFirestore
	) {
		this.loadRangeMasterList();
	}

	private loadRangeMasterList() {
		const sub = this.db
			.collection<RangeInfo>("/ranges")
			.valueChanges()

			.subscribe((docs) => {
				this.rangeMasterList = docs.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
				this.loadedData = true;
				this.pushRanges();
			});

		this.subsink.add(sub);
	}

	initialise(): void {
		if (!environment.production) {
		}
	}

	ngOnDestroy(): void {
		this.subsink.unsubscribe();
	}

	private pushRanges() {
		const cats = this.rangeMasterList.filter((r) => r.active);
		this.activeRanges$.next(cats);
		this.allRanges$.next(this.rangeMasterList);
	}

	getNameByID(id: string) {
		const element = this.rangeMasterList.find((c) => c.id == id);
		if (!!element) {
			return element.name;
		} else {
			return "";
		}
	}

	get designsWithPhotos(): Observable<string[]> {
		return this.activeRanges$.pipe(
			skipWhile((r) => !this.loadedData),
			take(1),
			map((r) => {
				if (!environment.production) {
				}
				return r
					.filter((ri) => {
						if (!!ri.photoExists) {
							return ri.photoExists;
						} else {
							return false;
						}
					})
					.sort((a, b) => RangeService.SortRange(a, b))
					.map((r) => r.id);
			}),
			tap((ri) => {
				if (!environment.production) {
				}
			})
		);
	}

	static SortRange(a: RangeInfo, b: RangeInfo) {
		if (a.sortOrder == undefined && b.sortOrder == undefined) {
			return a.name.localeCompare(b.name);
		} else if (a.sortOrder == undefined) {
			return 1;
		} else if (b.sortOrder == undefined) {
			return -1;
		}
		const aSort = a.sortOrder as Number;
		const bSort = b.sortOrder as Number;

		let result: number;
		if (aSort == bSort) {
			result = 0;
		} else if (aSort < bSort) {
			result = -1;
		} else {
			result = 1;
		}

		return result;
	}
}
