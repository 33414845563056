import { Component, OnInit } from '@angular/core';

interface OrderStep {
  step: number;
  icon: string;
  info: string;
  div: string;
}

const orderSteps: OrderStep[] = [
  {
    step: 1,
    icon: 'pi-shopping-cart',
    info: 'Browse the website and add items to you order. (shopping, yay)',
    div: 'one',
  },
  {
    step: 2,
    icon: 'pi-check',
    info: 'Click place order. (no more long forms!)',
    div: 'two',
  },
  {
    step: 3,
    icon: 'pi-user',
    info:
      'A representative will get back to you within a day to confirm your order and give you a lead time on your order.',
    div: 'three',
  },
  {
    step: 4,
    icon: 'pi-file',
    info:
      'When your order is wrapped up and boxed you’ll be issued an invoice.',
    div: 'four',
  },
  {
    step: 5,
    icon: 'pi-credit-card',
    info:
      'Once we have you proof of payment, your order will be dispatched. Woohoo!',
    div: 'five',
  },
  {
    step: 6,
    icon: 'pi-compass',
    info: 'You’ll receive a dispatch notification and a tracking number.',
    div: 'six',
  },
  {
    step: 7,
    icon: 'pi-phone',
    info:
      "We'll give you a call once your order has arrived to make sure you’re happy!",
    div: 'seven',
  },
];

@Component({
  selector: 'app-order-process',
  templateUrl: './order-process.component.html',
  styleUrls: ['./order-process.component.scss'],
})
export class OrderProcessComponent implements OnInit {
  constructor() {}

  orderProcess: OrderStep[] = orderSteps;

  ngOnInit(): void {}
}
