import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: "root",
})
export class OverlaysService {
	private showContactHelp$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private showCartOverlay$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private showLoadingOverlay$: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private loadingOverlayText$: BehaviorSubject<string> = new BehaviorSubject(
		"Loading"
	);

	private overlayInfoForSidebar: { contact: boolean; cart: boolean } = {
		cart: false,
		contact: false,
	};

	private overlayInfoForCartSidebar: { contact: boolean } = {
		contact: false,
	};

	get contact() {
		return this.showContactHelp$.asObservable();
	}

	get cart() {
		return this.showCartOverlay$.asObservable();
	}

	get showLoadingOverlay() {
		return this.showLoadingOverlay$.asObservable();
	}

	get loadingOverlayText() {
		return this.loadingOverlayText$.asObservable();
	}

	constructor() {}

	showContactHelp(value: boolean) {
		this.showContactHelp$.next(value);
	}

	showCartOverlay(value: boolean) {
		this.showCartOverlay$.next(value);
	}

	initialise(): void {
		if (!environment.production) {
		}
	}

	sidebarOpened(): void {
		this.overlayInfoForSidebar = {
			contact: this.showContactHelp$.value,
			cart: this.showCartOverlay$.value,
		};
		if (!environment.production) {
		}
		this.showContactHelp$.next(false);
		this.showCartOverlay$.next(false);
	}

	sidebarClosed(): void {
		if (!environment.production) {
		}
		this.showCartOverlay$.next(this.overlayInfoForSidebar.cart);
		this.showContactHelp$.next(this.overlayInfoForSidebar.contact);
	}

	cartSidebarOpened(): void {
		this.overlayInfoForCartSidebar.contact = this.showContactHelp$.value;
		this.showContactHelp$.next(false);
	}

	cartSidebarClosed(): void {
		this.showContactHelp$.next(this.overlayInfoForCartSidebar.contact);
	}

	activateLoadingOverlay(value: string = "Loading") {
		this.showLoadingOverlay$.next(true);
		this.loadingOverlayText$.next(value);
	}

	deactivateLoadingOverlay() {
		this.showLoadingOverlay$.next(false);
		this.loadingOverlayText$.next("Loading");
	}
}
