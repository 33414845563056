<div [ngClass]="{ row: row }" class="container">
  <div class="filter-title">
    <div class="subtitle">
      <div class="description">Filter</div>
      <div
        (click)="clearFilter()"
        *ngIf="currentFilter.hasClearableFilter"
        class="clear"
      >
        Clear All
      </div>
    </div>
  </div>
  <hr />
  <div class="subfilter category-filter">
    <div class="subtitle">
      <div class="description">Category</div>
      <div
        (click)="clearCategories()"
        *ngIf="selectedCategories.length > 0"
        class="clear"
      >
        Clear
      </div>
    </div>
    <p-multiSelect
      [resetFilterOnHide]="true"
      [showToggleAll]="false"
      [virtualScroll]="true"
      [(ngModel)]="selectedCategories"
      [options]="(categories$ | async) || []"
      [optionLabel]="'name'"
      [optionValue]="'id'"
      defaultLabel="Select Categories"
      (onChange)="filter()"
      [maxSelectedLabels]="0"
    >
    </p-multiSelect>
  </div>
  <hr />
  <div class="subfilter range-filer">
    <div class="subtitle">
      <div class="description">Range</div>
      <div
        (click)="clearRanges()"
        *ngIf="selectedRanges.length > 0"
        class="clear"
      >
        Clear
      </div>
    </div>
    <p-multiSelect
      [resetFilterOnHide]="true"
      [showToggleAll]="false"
      [virtualScroll]="true"
      [(ngModel)]="selectedRanges"
      [options]="(ranges$ | async) || []"
      [optionLabel]="'name'"
      (onChange)="filter()"
      [optionValue]="'id'"
      defaultLabel="Select Ranges"
      [maxSelectedLabels]="0"
    >
    </p-multiSelect>
  </div>
  <hr />
  <div class="subfilter design-filter">
    <div class="subtitle">
      <div class="description">Design</div>
      <div
        (click)="clearDesigns()"
        *ngIf="selectedDesigns.length > 0"
        class="clear"
      >
        Clear
      </div>
    </div>
    <p-multiSelect
      [resetFilterOnHide]="true"
      [showToggleAll]="false"
      [virtualScroll]="true"
      [(ngModel)]="selectedDesigns"
      [options]="(designs$ | async) || []"
      [optionLabel]="'name'"
      (onChange)="filter()"
      [optionValue]="'id'"
      defaultLabel="Select designs"
      [maxSelectedLabels]="0"
    >
    </p-multiSelect>
  </div>
  <hr />
  <div class="subfilter price-filter">
    <div class="subtitle">
      <div class="description">Price</div>
      <div
        (click)="clearPrice()"
        *ngIf="minPrice || maxPrice || onSale"
        class="clear"
      >
        Clear
      </div>
    </div>
    <div class="inputs">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"> {{ currencySymbol$ | async }}</span>
        <input
          (change)="filter()"
          [(ngModel)]="minPrice"
          type="number"
          pInputText
          placeholder="Enter Price"
        />
        <span class="p-inputgroup-addon end-addon">Min</span>
      </div>
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">{{ currencySymbol$ | async }}</span>
        <input
          (change)="filter()"
          [(ngModel)]="maxPrice"
          type="number"
          pInputText
          placeholder="Enter Price"
        />
        <span class="p-inputgroup-addon end-addon">Max</span>
      </div>
      <div *ngIf="!wholesaleClient" class="p-field-checkbox">
        <p-checkbox
          (onChange)="filter()"
          [(ngModel)]="onSale"
          [binary]="true"
          inputId="binary"
        >
        </p-checkbox>
        <label for="binary">On Sale</label>
      </div>
    </div>
  </div>
  <hr />
</div>
