import {
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import { MenuItem } from "primeng/api/menuitem";
import { Subscription } from "rxjs";
import { SubSink } from "subsink";

import { LayoutService, ScreenSize } from "../services/layout.service";
import { OverlaysService } from "./../services/overlays.service";
import { UserService } from "./../services/user.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
	mainMenuItems: MenuItem[] = [];

	menuPosition: any;
	menuHeight: any;
	sticky: boolean = false;
	screenSize!: ScreenSize;
	screenSub!: Subscription;

	subsink = new SubSink();

	sidebarVisible = false;
	gottenHeight = false;

	@ViewChild("logo", { static: false }) logo!: ElementRef;
	@ViewChild("stickyNav", { static: false }) nav!: ElementRef;
	@ViewChild("mobileNav", { static: false }) mobileNav!: ElementRef;

	constructor(
		private ovs: OverlaysService,
		private ss: LayoutService,
		private us: UserService
	) {}
	ngOnDestroy(): void {
		this.subsink.unsubscribe();
	}

	@HostListener("window:scroll", ["$event"])
	handleScroll() {
		const windowScroll = window.pageYOffset;
		if (windowScroll >= this.menuPosition) {
			this.sticky = true;
		} else {
			this.sticky = false;
		}
	}

	inView(event: IntersectionObserverEntry, element: HTMLElement): void {
		if (!this.sticky && !this.screenSize.extraSmall) {
			this.menuPosition = element.offsetTop;
		}
	}

	ngOnInit(): void {
		this.mainMenuItems = [
			// {
			// 	label: "Home",
			// 	routerLink: "/home",
			// 	command: this.closeSidebar.bind(this),
			// },
			// {
			// 	label: "Shop",
			// 	items: [
			// 		{
			// 			label: "Shop All Products",
			// 			routerLink: "/shop/products",
			// 			queryParamsHandling: "merge",
			// 			queryParams: {
			// 				design: null,
			// 				category: null,
			// 				range: null,
			// 				sale: null,
			// 				min: null,
			// 				max: null,
			// 			},
			// 			command: this.closeSidebar.bind(this),
			// 		},
			// 		{
			// 			separator: true,
			// 		},
			// 		{
			// 			label: "Shop Via Order Form",
			// 			routerLink: "/wholesale/order",
			// 			command: this.closeSidebar.bind(this),
			// 		},
			// 		{
			// 			label: "Shop By Category",
			// 			routerLink: "/shop/categories",
			// 			command: this.closeSidebar.bind(this),
			// 		},
			// 		{
			// 			label: "Shop By Design Range",
			// 			routerLink: "/shop/ranges",
			// 			command: this.closeSidebar.bind(this),
			// 		},
			// 	],
			// },

			{
				label: "Wholesale",
				items: [
					{
						label: "Login",
						routerLink: "/user/login",
						command: this.closeSidebar.bind(this),
					},
					{
						label: "Register",
						command: this.closeSidebar.bind(this),
						routerLink: "/user/register",
					},
				],
			},
			{
				label: "FAQ",
				command: this.closeSidebar.bind(this),
				routerLink: "/faq",
			},
			{
				label: "Account",
				items: [
					{
						label: "Login",
						routerLink: "/user/login",
						command: this.closeSidebar.bind(this),
					},
					{
						label: "Register",
						routerLink: "/user/register",
						command: this.closeSidebar.bind(this),
					},
				],
			},
		];

		this.subsink.add(
			this.ss.screenSize$.subscribe((next) => {
				this.screenSize = next;
			})
		);

		this.subsink.add(
			this.us.user.subscribe((user) => {
				if (!!user) {
					const adminStuff: MenuItem[] = [
						{
							label: "Admin Panel",
							command: this.closeSidebar.bind(this),
							routerLink: "/admin",
						},
						{
							label: "Use USD",
							command: () => {
								this.us.changeRegion("US");
								this.closeSidebar();
							},
						},
						{
							label: "Use EUR",
							command: () => {
								this.us.changeRegion("EU");
								this.closeSidebar();
							},
						},
						{
							label: "Use EUR 2",
							command: () => {
								this.us.changeRegion("EU2");
								this.closeSidebar();
							},
						},
						{
							label: "Use ZAR",
							command: () => {
								this.us.changeRegion("SA");
								this.closeSidebar();
							},
						},
						{
							label: "Logout",
							command: () => {
								this.us.logoutUser();
								this.closeSidebar();
							},
						},
					];

					let i =
						user.region == "SA"
							? 4
							: user.region == "EU"
							? 2
							: user.region == "US"
							? 1
							: user.region == "EU2"
							? 3
							: null;

					if (!!i) {
						adminStuff.splice(i, 1);
					}

					const normalStuff = [
						{
							label: "View Orders",
							command: this.closeSidebar.bind(this),
							routerLink: "/user/orders",
						},
						{
							label: "Logout",
							command: () => {
								this.us.logoutUser();
								this.closeSidebar();
							},
						},
					];
					this.mainMenuItems[2] = {
						label: "Account",
						items: user.admin ? adminStuff : normalStuff,
					};
					this.mainMenuItems[0].items = [
						{
							label: "Online Order Form",
							routerLink: "/wholesale/order",
							command: this.closeSidebar.bind(this),
						},
						{
							label: "View Order History",
							command: this.closeSidebar.bind(this),
							routerLink: "/user/orders",
						},
					];
				} else {
					this.mainMenuItems[2] = {
						label: "Account",
						items: [
							{
								label: "Login",
								routerLink: "/user/login",
								command: this.closeSidebar.bind(this),
							},
							{
								label: "Register",
								routerLink: "/user/register",
								command: this.closeSidebar.bind(this),
							},
						],
					};
					this.mainMenuItems[0] = {
						label: "Wholesale",
						items: [
							{
								label: "Login",
								routerLink: "/user/login",
								command: this.closeSidebar.bind(this),
							},
							{
								label: "Register",
								routerLink: "/user/register",
								command: this.closeSidebar.bind(this),
							},
						],
					};
				}
			})
		);
	}

	closeSidebar(): void {
		this.sidebarVisible = false;
	}

	sidebarShow(): void {
		this.ovs.sidebarOpened();
	}

	sidebarHide(): void {
		this.ovs.sidebarClosed();
	}
}
