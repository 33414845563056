<div
  [ngClass]="{ addedItem: fullWidth }"
  class="container"
  (click)="gotoCart()"
  *ngIf="screenSize.medium || screenSize.large"
>
  <!-- <i class="pi pi-angle-left cart-icon"></i>
  <i class="pi pi-shopping-cart cart-icon"></i>
  <div class="qty">0 items in cart</div> -->

  <div class="arrow">
    <i class="pi pi-angle-left"></i>
  </div>
  <div class="cart">
    <i class="pi pi-shopping-cart"></i>
  </div>
  <div *ngIf="!justAddedItem" class="qty">{{ quantity | async }} items</div>
  <div *ngIf="!justAddedItem" class="total">
    {{ (orderTotal | async)?.exclVAT | myCurrency: "1.2-2" }}<br />
  </div>
  <div class="itemAdded" *ngIf="justAddedItem">Item Added</div>
</div>
<div
  [ngClass]="{ addedItem: fullWidth }"
  class="container"
  (click)="gotoCart()"
  *ngIf="screenSize.small || screenSize.extraSmall"
>
  <!-- <i class="pi pi-angle-left cart-icon"></i>
  <i class="pi pi-shopping-cart cart-icon"></i>
  <div class="qty">0 items in cart</div> -->

  <div class="label">Order</div>
  <div class="info">
    <div *ngIf="!justAddedItem" class="qty">{{ quantity | async }} items</div>
    <div *ngIf="!justAddedItem" class="total">
      {{
        (orderTotal | async)?.exclVAT | currency: "ZAR":"symbol-narrow":"1.2-2"
      }}<br />
      (excl. VAT)
    </div>
    <div class="itemAdded" *ngIf="justAddedItem">Item Added</div>
  </div>
</div>
<p-sidebar
  [style]="{
    width: screenSize.extraSmall || screenSize.small ? '97.5vw' : '60vw'
  }"
  position="right"
  [(visible)]="showSidebar"
  (onShow)="sidebarShow()"
  (onHide)="sidebarHide()"
>
  <div class="sidebar">
    <div class="heading">Your Current Order</div>
    <div class="cart">
      <app-cart [mini]="true"></app-cart>
    </div>
    <div *ngIf="currency == 'ZAR'" class="totals">
      <div class="order-total">
        <p class="info">Subtotal (excl. VAT)</p>
        <p class="currency">R</p>
        <p class="total-amount">
          {{ ((orderTotal | async)?.exclVAT || 0).toFixed(2) }}
        </p>
      </div>
      <div class="order-total">
        <p class="info">VAT (15%)</p>
        <p class="currency">R</p>
        <p class="total-amount">
          {{
            (
              ((orderTotal | async)?.inclVAT || 0) -
              ((orderTotal | async)?.exclVAT || 0)
            ).toFixed(2)
          }}
        </p>
      </div>
      <div class="order-total">
        <p class="info">Total (incl. VAT)</p>
        <p class="currency">R</p>
        <p class="total-amount">
          {{ ((orderTotal | async)?.inclVAT || 0).toFixed(2) }}
        </p>
      </div>
    </div>

    <div *ngIf="currency == 'USD'" class="totals">
      <div class="order-total">
        <p class="info">Total</p>
        <p class="currency">$</p>
        <p class="total-amount">
          {{ ((orderTotal | async)?.exclVAT || 0).toFixed(2) }}
        </p>
      </div>
    </div>
    <div *ngIf="currency == 'EUR'" class="totals">
      <div class="order-total">
        <p class="info">Total</p>
        <p class="currency">€</p>
        <p class="total-amount">
          {{ ((orderTotal | async)?.exclVAT || 0).toFixed(2) }}
        </p>
      </div>
    </div>
    <div class="ctas">
      <a [routerLink]="['/shop', 'cart']"
        ><button
          appButton
          [cta]="true"
          [outline]="true"
          label="View Full Order"
          (click)="showSidebar = false"
        ></button
      ></a>
      <a [routerLink]="['/shop', 'cart']"
        ><button
          appButton
          [cta]="true"
          [outline]="false"
          (click)="showSidebar = false"
          label="Proceed to Checkout"
        ></button
      ></a>
    </div>
  </div>
</p-sidebar>
