import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubSink } from 'subsink';

import { UserService } from '../../../services/user.service';
import { LayoutService, ScreenSize } from './../../../services/layout.service';

@Component({
  selector: 'app-product-catalogue-infobar',
  templateUrl: './product-catalogue-infobar.component.html',
  styleUrls: ['./product-catalogue-infobar.component.scss'],
})
export class ProductCatalogueInfobarComponent implements OnInit {
  @Input() numProducts!: number;
  @Output() showExVatPrices: EventEmitter<boolean> = new EventEmitter();
  @Input() wholesaleClient: boolean = false;

  saClient: boolean = true;
  priceOptions: PriceType[] = [
    {
      type: 'incl',
      label: 'incl. VAT',
    },
    {
      type: 'excl',
      label: 'excl. VAT',
    },
  ];

  selectedPriceOption: PriceType;
  subsink: SubSink = new SubSink();
  screenSize!: ScreenSize;
  constructor(private ls: LayoutService, private us: UserService) {
    this.selectedPriceOption = this.priceOptions[1];
    this.subsink.add(
      this.ls.screenSize.subscribe((ss) => {
        this.screenSize = ss;
      })
    );

    this.subsink.add(
      this.us.user.subscribe((user) => {
        if (!user) {
          this.saClient = true;
        } else {
          this.saClient = user.region == 'SA';
        }
      })
    );
  }

  ngOnInit(): void {}

  priceChange() {
    if (this.selectedPriceOption.type == 'excl') {
      this.showExVatPrices.next(true);
    } else {
      this.showExVatPrices.next(false);
    }
  }
}

interface PriceType {
  type: 'excl' | 'incl';
  label: string;
}
