import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { ProductFilter } from './../models/product-filter.model';
import { CategorySortedProductList, Product } from './../models/product.model';
import { FilterService } from './filter.service';
import { LayoutService } from './layout.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: "root",
})
export class ProductService implements OnDestroy {
	private productMasterList: Product[] = [];
	private productFilteredList: Product[] = [];
	private productFilter: ProductFilter = ProductFilter.noFilter();
	private pageSize: number = 72;
	private filterSub: Subscription;
	private sink: SubSink = new SubSink();

	private isMobile: boolean = false;

	private isLoadingProducts$ = new BehaviorSubject<boolean>(false);
	private productMasterList$ = new BehaviorSubject<Product[]>(
		this.productMasterList
	);
	private productFilteredList$ = new BehaviorSubject<Product[]>(
		this.productFilteredList
	);
	private products$ = new BehaviorSubject<Product[]>(this.productMasterList);
	private canLoadMoreProducts$ = new BehaviorSubject<boolean>(false);
	private productQuantity$ = new BehaviorSubject<number>(0);

	get isLoadingProducts() {
		return this.isLoadingProducts$.asObservable();
	}

	get canLoadMoreProducts() {
		return this.canLoadMoreProducts$.asObservable();
	}

	get products() {
		return this.products$.asObservable();
	}

	get masterProducts() {
		return this.productMasterList$.asObservable();
	}

	get masterFilteredProducts() {
		return this.productFilteredList$.asObservable();
	}

	get productQuantity() {
		return this.productQuantity$.asObservable();
	}

	get categorySortedProducts(): Observable<CategorySortedProductList> {
		return this.products.pipe(
			skipWhile((pml) => pml.length == 0),
			map((pml) => {
				let start = new Date();
				const catIDs = pml.map((p) => p.type.id);

				const uniqueCatIDs = [...new Set(catIDs)];
				const result: CategorySortedProductList = {};

				uniqueCatIDs.forEach((id) => {
					result[id] = pml.filter((p) => p.type.id == id).slice(0, 1);
				});

				let end = new Date();
				return result;
			})
		);
	}

	constructor(
		private fs: FilterService,
		private ls: LayoutService,
		private db: AngularFirestore,
		private us: UserService
	) {
		this.loadMasterProductList();
		this.us.user.subscribe((u) => {
			let region = "zar";
			switch (u?.region) {
				case "EU":
					region = "eur";
					break;
				case "US":
					region = "usd";
					break;
				case "EU2":
					region = "eur2";
					break;
				default:
					region = "zar";
			}

			if (region == "eur2") {
				this.loadMasterProductList(true, region);
			}
		});
		this.filterSub = this.fs.productFilter.subscribe(
			(filter) => this.handleNewFilter(filter),
			this.handleNewFilterError
		);
		this.sink.add(this.filterSub);
		this.sink.add(
			this.ls.screenSize$.subscribe((next) => {
				if (next.extraSmall || next.small) {
					this.pageSize = 50;
					this.isMobile = true;
				} else {
					this.pageSize = 75;
					this.isMobile = false;
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.filterSub.unsubscribe();
		this.sink.unsubscribe();
	}

	initialise(): void {
		if (!environment.production) {
		}
	}

	private loadMasterProductList(
		loadActive: boolean = true,
		region: string = "zar"
	): void {
		this.isLoadingProducts$.next(true);

		const sub = this.db
			.collection<Product>("/mcProducts", (ref) => {
				if (region == "eur2") {
					return ref
						.where("regionAvailability.eur2", "==", true)
						.where("active", "==", true)
						.orderBy("type.name")
						.orderBy("range.name")
						.orderBy("design.name");
				}
				return ref
					.where("active", "==", loadActive)
					.orderBy("type.name")
					.orderBy("range.name")
					.orderBy("design.name");
			})
			.valueChanges()
			.subscribe((docs) => {
				if (!environment.production) {
				}
				if (region == "eur2") {
					this.productMasterList = docs.filter(
						(d) => d.priceInfo.eur2.exclVAT > 0
					);
				} else {
					this.productMasterList = docs;
				}

				if (!environment.production) {
				}
				this.isLoadingProducts$.next(false);
				this.productMasterList$.next(this.productMasterList);
				if (this.productFilter.hasFilters) {
					this.handleNewFilter(this.productFilter);
				} else {
					this.handleNewFilter(ProductFilter.noFilter());
				}
			});
		this.sink.add(sub);
	}

	private defaultProductSort(a: Product, b: Product): number {
		const typeA = a.type.name;
		const typeB = b.type.name;
		if (typeA.localeCompare(typeB) !== 0) {
			return typeA.localeCompare(typeB);
		} else {
			const rangeA = a.range.name;
			const rangeB = b.range.name;
			if (rangeA.localeCompare(rangeB) !== 0) {
				return rangeA.localeCompare(rangeB);
			} else {
				const designA = a.design.name;
				const designB = b.design.name;
				return designA.localeCompare(designB);
			}
		}
	}

	pushProducts(): void {
		if (this.productFilter.hasFilters) {
			this.productFilteredList = this.productMasterList.filter((p) =>
				this.productFilter.check(p)
			);
			this.productFilteredList$.next(this.productFilteredList);
		} else {
			this.productFilteredList = this.productMasterList.slice();
			this.productFilteredList$.next(this.productFilteredList);
		}
		const qty = this.productFilteredList.length;
		const products = this.productFilteredList.slice(0, this.pageSize);
		this.canLoadMoreProducts$.next(
			this.productFilteredList.length > this.pageSize
		);
		this.products$.next(products);
		this.productQuantity$.next(qty);
	}

	loadMoreProducts(): void {
		const canLoadMore = this.canLoadMoreProducts$.value;
		if (canLoadMore) {
			this.pageSize += this.isMobile ? 50 : 75;
			this.pushProducts();
		}
	}

	private handleNewFilter(filter: ProductFilter): void {
		this.pageSize = this.isMobile ? 50 : 75;
		this.productFilter = filter;
		this.pushProducts();
	}

	private handleNewFilterError(error: any): void {
		console.error(error);
	}

	getProduct(id: string) {
		return this.productMasterList$.pipe(
			skipWhile((pml) => pml.length == 0),
			map((pml) => {
				const prod = pml.find((p) => p.id == id);
				if (!prod) {
					throw new Error("Product ID is invalid");
				} else {
					return prod;
				}
			})
		);
	}

	loadInactiveProducts() {
		this.loadMasterProductList(false);
	}

	loadActiveProducts() {
		this.loadMasterProductList(true);
	}

	getProductsInRange(rangeID: string) {
		return this.productMasterList$.value.filter((p) => p.range.id == rangeID);
	}

	getProductsWithDesign(designID: string) {
		return this.productMasterList$.value.filter((p) => p.design.id == designID);
	}
}
