import { Component, OnInit } from '@angular/core';

interface FAQ {
  question: string;
  answer: string[];
}

const faqs: FAQ[] = [
  {
    question: 'How long do I have to wait for my order?',
    answer: [
      'Our standard lead time on orders is two to four weeks for products that are out of stock.',
      'Your lead time will be confirmed upon receipt of your order.',
      'If all of the items on order are in stock, please allow for 2 working days for packing and quality checking.',
    ],
  },
  {
    question: 'Payment! How, when and why?',
    answer: [
      'If it’s your first time ordering with us we’ll need a 50% deposit on the order.',
      'Once your order has been packed and is ready for dispatch you will be invoiced.',
      'Payment can be made via EFT (additional options available for international orders)',
      'Once you’ve sent through your proof payment your order will be dispatched! Awesomeness!',
    ],
  },
  {
    question: 'What if I want something special?',
    answer: [
      'If you’d like to order something that isn’t available on the website please pop Sasha an email at sasha@alovesupreme.co.za and we’ll see what we can do for you!',
    ],
  },
  {
    question: 'Yikes! I made a mistake. How do I change my order?',
    answer: [
      'Pop an email to Sasha (for national orders) or Leanne (for international orders) and we’ll help you out.',
    ],
  },
];

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  faqs: FAQ[] = faqs;
  constructor() {}

  ngOnInit(): void {}
}
