<div #container class="container fullscreenSection">
  <div class="title heading-text">Design Ranges</div>
  <div
    [ngClass]="{ wah: i == 0, cla: i == 1, pc: i == 2 }"
    class="range"
    *ngFor="let range of ranges; let i = index"
  >
    <a
      [routerLink]="['/', 'shop', 'products']"
      [queryParams]="{ range: range.id }"
      [class]="'image ' + j"
      *ngFor="let j of ['a', 'b', 'c', 'd']; let k = index"
      ><div>
        <img
          [src]="
            'https://res.cloudinary.com/alovesupreme/image/upload/f_auto,t_range_' +
            (screenSize.small || screenSize.extraSmall ? 's' : 'l') +
            range.imageUrl +
            (k + 1) +
            '.jpg'
          "
        /></div
    ></a>
    <div class="name">
      <a
        [routerLink]="['/', 'shop', 'products']"
        [queryParams]="{ range: range.id }"
        ><button
          style="width: 55%"
          appButton
          [outline]="true"
          [mini]="true"
          [hoverEffect]="true"
          [label]="range.name"
        ></button
      ></a>
    </div>
  </div>

  <div class="bgut">
    <a [routerLink]="['/', 'shop', 'ranges']">
      <button appButton [cta]="true" label="Shop By Design Range"></button
    ></a>
  </div>
</div>
