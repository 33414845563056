import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Currency, LineItem } from 'src/app/models/order.model';
import { Product } from 'src/app/models/product.model';
import { ScreenSize } from 'src/app/services/layout.service';
import { OrderService } from 'src/app/services/order.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

import { UserService } from '../../../services/user.service';
import { LayoutService } from './../../../services/layout.service';

@Component({
	selector: "app-cart-line-item",
	templateUrl: "./cart-line-item.component.html",
	styleUrls: ["./cart-line-item.component.scss"],
})
export class CartLineItemComponent implements OnInit, OnDestroy {
	@Input() lineItem!: LineItem;
	@Input() mini: boolean = false;
	@Input() isWholesaleClient: boolean = false;
	qty!: number;
	@Input() editing: boolean = false;

	currency: Currency = "ZAR";
	screenSize!: ScreenSize;
	subsink = new SubSink();
	constructor(
		private os: OrderService,
		private ls: LayoutService,
		private us: UserService
	) {
		this.subsink.add(
			this.ls.screenSize.subscribe((ss) => {
				this.screenSize = ss;
			})
		);
		this.subsink.add(
			this.us.user.subscribe((user) => {
				const region = user?.region;

				switch (region) {
					case "US":
						this.currency = "USD";
						break;
					case "EU":
						this.currency = "EUR";
						break;
					case "UK":
						this.currency = "GBP";
						break;
					case "EU2":
						this.currency = "EUR2";
						break;
					default:
						this.currency = "ZAR";
						break;
				}
			})
		);
	}
	ngOnDestroy(): void {
		this.subsink.unsubscribe();
	}

	ngOnInit(): void {
		this.qty = this.lineItem.quantity;
	}

	get imageURL(): string {
		return `${environment.cloudinary.galleryThumbnailImageUrl}/products/${this.lineItem.product.id}`;
	}

	get productType(): string {
		const product: Product = this.lineItem.product;
		if (!product.type.nameInProduct) {
			return product.type.name;
		} else {
			return product.type.nameInProduct;
		}
	}

	get design(): string {
		return this.lineItem.product.design.name;
	}

	qtyChanged(event: { value: number }) {
		this.os.setItemQuantity(this.lineItem.product, event.value);
	}

	removeItem() {
		this.os.removeItem(this.lineItem.product.id);
	}
}
