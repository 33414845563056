import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { FaqComponent } from "./faq/faq.component";

const routes: Routes = [
	{
		path: "",
		pathMatch: "full",
		redirectTo: "/user/register",
	},
	// {
	// 	path: "redirect",
	// 	pathMatch: "full",
	// 	component: RedirectComponent,
	// },
	{
		path: "admin",
		loadChildren: () => {
			return import("./admin/admin.module").then((m) => m.AdminModule);
		},
	},
	// {
	// 	path: "shop",
	// 	loadChildren: () => {
	// 		return import("./shop/shop.module").then((m) => m.ShopModule);
	// 	},
	// },
	{
		path: "user",
		loadChildren: () => {
			return import("./user/user.module").then((m) => m.UserModule);
		},
	},
	// {
	// 	path: "wholesale",
	// 	loadChildren: () => {
	// 		return import("./wholesale/wholesale.module").then(
	// 			(m) => m.WholesaleModule
	// 		);
	// 	},
	// },
	{
		path: "faq",
		component: FaqComponent,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			scrollPositionRestoration: "top",
			anchorScrolling: "enabled",
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
