<div class="container">
	<div class="title heading-text">Frequently Asked Questions</div>
	<div class="faq">
		<p-accordion [multiple]="true">
			<p-accordionTab
				[selected]="true"
				header="How long do I have to wait for my order?"
			>
				<p>Your lead time will be confirmed upon receipt of your order.</p>

				<p>
					If all items on order are in stock, please allow for 2 working days
					for packing and quality checking.
				</p>

				<p>
					Our standard lead time on orders is between 2 and 6 weeks, depending
					on whether the products you’d like are already in production. If that
					wait will be too long we can suggest alternatives.
				</p>
			</p-accordionTab>
			<p-accordionTab
				[selected]="true"
				header="Is there a minimum order quantity?"
			>
				<p>
					We have a minimum order quantity of R3500 ex VAT for first orders and
					R2500 ex VAT for replenishment orders.
				</p>

				<p>
					This helps cover the admin and labour that goes into the processing of
					each order. And as a small business it also ensures that our wholesale
					prices can stay low, while being profitable to us.
				</p>
			</p-accordionTab>
			<p-accordionTab [selected]="true" header="Payment! How, when and why?">
				<p>
					If it’s your first time ordering with us we’ll need a 50% deposit on
					the order.
				</p>
				<p>
					Once your order has been packed and is ready for dispatch you will be
					invoiced.
				</p>
				<p>
					Payment can be made via EFT (additional options available for
					international orders)',
				</p>
				<p>
					Once you’ve sent through your proof payment your order will be
					dispatched! Awesomeness!
				</p>
			</p-accordionTab>
			<p-accordionTab
				[selected]="true"
				header="What if I want something special?"
			>
				<p>
					If you’d like to order something that isn’t available on the website
					please pop Melissa an email at
					<a href="mailto:melissa@alovesupreme.co.za"
						>melissa@alovesupreme.co.za</a
					>
					and we’ll see what we can do for you!
				</p>
			</p-accordionTab>
			<p-accordionTab
				[selected]="true"
				header="Yikes! I made a mistake. How do I change my order?"
			>
				<p>
					Pop an email to Melissa (for national orders) or Leanne (for
					international orders) and we’ll help you out.
				</p>
			</p-accordionTab>
		</p-accordion>
	</div>
</div>
