<div [ngClass]="{ minify: mini }" class="container">
  <div class="details">
    <img
      *ngIf="!mini || screenSize.large || screenSize.medium"
      [src]="imageURL"
      class="line-item-image"
      alt=""
    />
    <div class="detailed-info">
      <p class="type">{{ productType }}</p>
      <p class="designInfo">{{ design }}</p>
      <p class="priceInfo">
        {{ lineItem.unitAmount.exclVAT | myCurrency }} each
        <span *ngIf="currency == 'ZAR'">(excl. VAT)</span>
      </p>
    </div>
  </div>
  <div class="qty">
    <div *ngIf="!editing" class="quantity">{{ lineItem.quantity }}</div>
    <p-inputNumber
      *ngIf="editing"
      [min]="0"
      [ngClass]="{ mobile: screenSize.extraSmall || screenSize.small }"
      [showButtons]="true"
      [(ngModel)]="qty"
      (onInput)="qtyChanged($event)"
      buttonLayout="stacked"
      spinnerMode="vertical"
      decrementButtonClass="p-button-danger"
      incrementButtonClass="p-button-success"
      incrementButtonIcon="pi pi-plus"
      decrementButtonIcon="pi pi-minus"
    ></p-inputNumber>
  </div>
  <div class="total">
    {{ lineItem.total.exclVAT | myCurrency: "1.2-2" }}
  </div>
  <div (click)="removeItem()" *ngIf="editing" class="edit">
    <i class="pi pi-times"></i>
  </div>
</div>
<hr />
